import React, { useState } from "react";
import { LocalStorageKeys } from "./constants";
import {
  localStorageSetAddValue,
  localStorageSetReadAllValues,
} from "./utils/localStorageStringSet";

const localStorageKey = LocalStorageKeys.TeachingDiscoveredFeatures;

type FeatureDiscoveryProviderProps = { children: React.ReactNode };

type FeatureId = "AuftragPositionAngelegt"; // mit | "Feature2" | "Feature3" erweitern

type State = {
  addDiscoveredFeature: (featureId: FeatureId) => void;
  hasDiscoveredFeature: (featureId: FeatureId) => boolean;
};

const FeatureDiscoveryContext = React.createContext<State | undefined>(
  undefined
);

const NO_FEATURES_DISCOVERED: string[] = [];

/**
 * Stellt einen React Context bereit, aus dem Child-Compoenents aufgrund von Nutzerinteraktion
 * Features als benutzt markieren bzw. als benutzt ermitteln können. Dies kann genutzt werden,
 * um für für eine nachfolgende Feature-Discovery weitere Hinweise / TeachingBubbles für den
 * Benutzer freizuschalten.
 */
function FeatureDiscoveryProvider({ children }: FeatureDiscoveryProviderProps) {
  const [discoveredFeatures, setDiscoveredFeatures] = useState(
    NO_FEATURES_DISCOVERED
  );

  React.useEffect(() => {
    setDiscoveredFeatures(localStorageSetReadAllValues(localStorageKey));
    let didCancel = false;
    const listener = (e: StorageEvent) => {
      if (e.key === localStorageKey && !didCancel) {
        setDiscoveredFeatures(localStorageSetReadAllValues(localStorageKey));
      }
    };

    window.addEventListener("storage", listener);
    return () => {
      didCancel = true;
      window.removeEventListener("storage", listener);
    };
  }, []);

  function addDiscoveredFeature(featureId: FeatureId) {
    setDiscoveredFeatures((state) =>
      state.includes(featureId) ? state : [...state, featureId]
    );
    localStorageSetAddValue(localStorageKey, featureId);
  }

  function hasDiscoveredFeature(featureId: FeatureId) {
    return discoveredFeatures.includes(featureId);
  }

  const value = {
    addDiscoveredFeature,
    hasDiscoveredFeature,
  };

  return (
    <FeatureDiscoveryContext.Provider value={value}>
      {children}
    </FeatureDiscoveryContext.Provider>
  );
}

function useFeatureDiscovery() {
  const context = React.useContext(FeatureDiscoveryContext);

  if (context === undefined) {
    throw new Error(
      "useFeatureDiscovery must be used within a FeatureDiscoveryProvider"
    );
  }

  return context;
}

export { FeatureDiscoveryProvider, useFeatureDiscovery };
