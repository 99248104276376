import React from "react";

import styles from "./Container.module.css";

type ContainerProps = {
  children: React.ReactNode;
  testId?: string;
};

export default function Container({ children, testId }: ContainerProps) {
  return (
    <div data-testid={testId} className={styles.container}>
      {children}
    </div>
  );
}
