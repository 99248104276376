import React from "react";

export const useIntersecting = (
  selector: string,
  initialValue: boolean = false,
  options?: IntersectionObserverInit
): boolean => {
  const [isIntersecting, setIsIntersecting] =
    React.useState<boolean>(initialValue);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries.length > 0) {
        // Hier wird absteigend nach Zeit sortiert
        // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry/time
        const entriesByTimeDescending = [...entries].sort(
          (a, b) => b.time - a.time
        );
        // Wenn mehrere Einträge gleichzeitig übergeben werden sollten, berücksichtigen wir
        // nur die letzte Änderung
        const [{ isIntersecting: elementIntersecting }] =
          entriesByTimeDescending;

        setIsIntersecting(elementIntersecting);
      }
    };

    const observer = new IntersectionObserver(callback, options);

    const target = document.querySelector(selector);

    if (target) {
      observer.observe(target);
    }

    return () => {
      observer.disconnect();
    };
  }, [isIntersecting, options, selector]);

  return isIntersecting;
};
