import React, { useMemo } from "react";
import Api from "./api";
import { useMessageActions } from "./messageActionsContext";

type ApiProviderProps = { children: React.ReactNode };

const ApiContext = React.createContext<Api | undefined>(undefined);

function ApiProvider({ children }: ApiProviderProps) {
  const messageActions = useMessageActions();
  const value = useMemo(
    () => Api.getInstance({ showMessage: messageActions?.showMessage }),
    [messageActions?.showMessage]
  );

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

function useApi() {
  const context = React.useContext(ApiContext);

  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }

  return context;
}

export { ApiProvider, useApi };
