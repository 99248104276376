import i18next from "i18next";

import cookieValue from "../utils/cookieValue";
import Api from "./Api";
import ApiResponse, {
  Failure,
  ForbiddenFailure,
  NotFoundFailure,
  Success,
  UnauthorizedFailure,
  ValidationFailure,
  GoneFailure,
  CompoundValidationFailure,
  ConflictFailure,
  BadGatewayFailure,
} from "./ApiResponse";

enum FetchMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type ServerValidationFieldMessage<T> = {
  fieldName: keyof T;
  message: string;
};

export type ServerValidationBody<T> = {
  fieldErrors?: ServerValidationFieldMessage<T>[];
  fieldWarnings?: ServerValidationFieldMessage<T>[];
  globalErrors?: string[];
  globalWarnings?: string[];
};

type BaseBody = Object | FormData | void;

const CSRF_COOKIE_NAME = "XSRF-TOKEN";

class FetchFactory<
  TResult,
  TBody extends BaseBody = void,
  TValidationResult = TBody
> {
  private method: FetchMethod;
  private url: string;
  private headers: Headers = new Headers();
  private body?: TBody;
  private api: Api;
  private _ensureCsrfToken: boolean = false;
  private _suppressMessages: boolean = false;

  constructor(api: Api, method: FetchMethod, url: string) {
    this.api = api;
    this.method = method;
    this.url = url;
    this.headers.set("Accept", "application/json");
  }

  static get<TResult, TValidationResult = undefined>(
    api: Api,
    url: string
  ): FetchFactory<TResult, undefined, TValidationResult> {
    return new FetchFactory<TResult, undefined, TValidationResult>(
      api,
      FetchMethod.GET,
      url
    );
  }

  static post<TResult, TBody extends BaseBody, TValidationResult = TBody>(
    api: Api,
    url: string,
    body?: TBody
  ): FetchFactory<TResult, TBody, TValidationResult> {
    const request = new FetchFactory<TResult, TBody, TValidationResult>(
      api,
      FetchMethod.POST,
      url
    );

    request.headers.set("Content-Type", "application/json");
    request.headers.set("X-XSRF-TOKEN", cookieValue(CSRF_COOKIE_NAME));
    request.body = body;

    return request;
  }

  static postFormData<TResult, TValidationResult = FormData>(
    api: Api,
    url: string,
    body?: FormData
  ): FetchFactory<TResult, FormData, TValidationResult> {
    const request = new FetchFactory<TResult, FormData, TValidationResult>(
      api,
      FetchMethod.POST,
      url
    );

    request.headers.set("X-XSRF-TOKEN", cookieValue(CSRF_COOKIE_NAME));
    request.body = body;

    return request;
  }

  static put<TResult, TBody extends BaseBody, TValidationResult = TBody>(
    api: Api,
    url: string,
    body: TBody
  ): FetchFactory<TResult, TBody, TValidationResult> {
    const request = new FetchFactory<TResult, TBody, TValidationResult>(
      api,
      FetchMethod.PUT,
      url
    );

    request.headers.set("Content-Type", "application/json");
    request.headers.set("X-XSRF-TOKEN", cookieValue(CSRF_COOKIE_NAME));
    request.body = body;

    return request;
  }

  static delete<T>(api: Api, url: string): FetchFactory<T> {
    const request = new FetchFactory<T>(api, FetchMethod.DELETE, url);

    request.headers.set("X-XSRF-TOKEN", cookieValue(CSRF_COOKIE_NAME));

    return request;
  }

  ensureCsrfToken(): FetchFactory<TResult, TBody, TValidationResult> {
    this._ensureCsrfToken = true;

    return this;
  }

  suppressMessages(): FetchFactory<TResult, TBody, TValidationResult> {
    this._suppressMessages = true;

    return this;
  }

  async fetch(): Promise<ApiResponse<TResult, TValidationResult>> {
    await this.csrfPrefetch();

    try {
      const response = await fetch(this.url, {
        method: this.method,
        headers: this.headers,
        body:
          this.body instanceof FormData ? this.body : JSON.stringify(this.body),
      });

      if (response.status === 401) {
        this.api.resetAuth();
        return new UnauthorizedFailure();
      }

      if (response.status === 403) {
        this.showMessage(i18next.t("errors.forbidden"));
        return new ForbiddenFailure();
      }

      if (response.status === 404) {
        let text;
        try {
          const { message } = await response.json();
          if (message) {
            text = message;
          }
        } catch {}

        return new NotFoundFailure(text);
      }

      if (response.status === 409) {
        let text;
        try {
          const { message } = await response.json();
          if (message) {
            text = message;
          }
        } catch {}

        return new ConflictFailure(text);
      }

      if (response.status === 410) {
        return new GoneFailure();
      }

      if (response.status === 400) {
        const data = await response.json();

        const isCompound = CompoundValidationFailure.isCompound(data);

        if (isCompound) {
          return CompoundValidationFailure.fromServerValidationBodies(data);
        } else {
          return ValidationFailure.fromServerValidationBody<TValidationResult>(
            data
          );
        }
      }

      // Die API liefert aktuell expliziet den Status-Code 504, wenn ein Dritt-System nicht erreichbar ist.
      // In diesem Fall soll der Login nicht moeglich sein (PORTAL-336).
      if (response.status === 502) {
        return new BadGatewayFailure();
      }

      if (!response.ok) {
        const errorKey =
          response.status === 503 || response.status === 504
            ? "errors.serviceUnavailable"
            : "errors.unknown";
        let text = i18next.t(errorKey);

        try {
          const { message } = await response.json();
          if (message) {
            text = message;
          }
        } catch {}

        this.showMessage(text);

        return new Failure();
      }

      const contentType = response.headers.get("Content-Type");
      if (contentType !== null && contentType.includes("application/json")) {
        const data = await response.json();

        return new Success(data);
      }
    } catch (error) {
      this.showMessage(i18next.t("errors.unknown"));
      return new Failure();
    }

    return new Success({} as TResult);
  }

  private async csrfPrefetch() {
    if (this._ensureCsrfToken === false) {
      return;
    }

    // Durch ein Logout Request wird der CSRF Cookie geloescht.
    // Fuer den Login Request wird jedoch ein gueltiger CSRF Token benoetigt.
    // Durch einen Request auf /api/profile bekommen wir einen CSRF Cookie.
    const csrfToken = cookieValue(CSRF_COOKIE_NAME);
    if (!csrfToken || csrfToken === "") {
      await fetch("/api/users/profile");
      this.headers.set("X-XSRF-TOKEN", cookieValue(CSRF_COOKIE_NAME));
    }
  }

  private showMessage(text: string) {
    if (this._suppressMessages) {
      return;
    }

    this.api.showMessage({
      type: "error",
      text,
    });
  }
}

export default FetchFactory;
