import { mergeStyleSets, Theme, useTheme } from "@fluentui/react";
import React, { CSSProperties } from "react";
import H1 from "./H1";
import H2 from "./H2";

type CardProps = {
  children: React.ReactNode;
  heading: string;
  subHeading?: string;
  maxWidth?: CSSProperties["maxWidth"];
  contentMarginTop?: CSSProperties["marginTop"];
};

function getClassNames(
  theme: Theme,
  maxWidth?: CSSProperties["maxWidth"],
  contentMarginTop?: CSSProperties["marginTop"]
) {
  return mergeStyleSets({
    container: {
      backgroundColor: theme.semanticColors.bodyBackground,
      borderWidth: 1,
      borderColor: theme.semanticColors.variantBorder,
      borderStyle: "solid",
      borderRadius: 4,
      maxWidth: maxWidth ?? 640,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "1rem",
    },
    content: {
      marginTop: contentMarginTop ?? "2.5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignSelf: "stretch",
    },
  });
}

export default function Card({
  children,
  contentMarginTop,
  heading,
  maxWidth,
  subHeading,
}: CardProps) {
  const theme = useTheme();
  const styles = getClassNames(theme, maxWidth, contentMarginTop);

  return (
    <div className={styles.container}>
      <H1>{heading}</H1>
      {subHeading && <H2 style={{ textAlign: "center" }}>{subHeading}</H2>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
