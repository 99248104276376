import React from "react";
import { mergeStyleSets, Theme, useTheme } from "@fluentui/react";

type BorderBoxProps = {
  children: React.ReactNode;
};

function getClassNames(theme: Theme) {
  return mergeStyleSets({
    container: {
      border: `1px solid ${theme.palette.neutralLight}`,
      borderRadius: 4,
      padding: "0.3125rem 0.625rem",
      height: "100%",
    },
  });
}

export default function BorderBox({ children }: BorderBoxProps) {
  const theme = useTheme();
  const styles = getClassNames(theme);

  return <div className={styles.container}>{children}</div>;
}
