import { useTranslation } from "react-i18next";

import Modal from "./Modal";
import TermsOfUse from "./TermsOfUse";

type TermsOfUseModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export default function TermsOfUseModal({
  onClose,
  ...props
}: TermsOfUseModalProps) {
  const { t } = useTranslation();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    return onClose();
  }

  return (
    <Modal
      {...props}
      submitLabel={t("action.close")}
      title="Nutzungsbedingungen"
      onSubmit={handleSubmit}
    >
      <TermsOfUse />
    </Modal>
  );
}
