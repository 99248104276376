import { mergeStyleSets, MessageBarType } from "@fluentui/react";
import { useMessageActions } from "../messageActionsContext";
import { MessageType, useMessage } from "../messageContext";
import GlobalMessageBar from "./GlobalMessageBar";

function getClassNames() {
  return mergeStyleSets({
    container: {
      position: "fixed",
      top: 0,
      left: "50%",
      marginLeft: -250,
      width: 500,
      zIndex: 1000,
    },
  });
}

function getMessageBarType(type: MessageType) {
  switch (type) {
    case "success":
      return MessageBarType.success;
    case "warning":
      return MessageBarType.warning;
    case "error":
      return MessageBarType.error;
  }
}

export default function Messages() {
  const message = useMessage();
  const messageActions = useMessageActions();
  const styles = getClassNames();

  return (
    <div className={styles.container}>
      {(message?.messages || []).map((m, index) => (
        <GlobalMessageBar
          key={index}
          type={getMessageBarType(m.type)}
          onDismiss={() => messageActions?.hideMessage(m)}
          isVisible={!m.hidden}
        >
          {m.text}
        </GlobalMessageBar>
      ))}
    </div>
  );
}
