import { mergeStyleSets } from "@fluentui/react";

type ModalBodyProps = {
  children: ((isLoading: boolean) => React.ReactNode) | React.ReactNode;
  isLoading?: boolean;
};

function getClassNames() {
  return mergeStyleSets({
    body: {
      marginTop: "1rem",
      marginBottom: "2rem",
    },
  });
}

export default function ModalBody({
  children,
  isLoading = false,
}: ModalBodyProps) {
  const styles = getClassNames();

  return (
    <div className={styles.body}>
      {typeof children === "function" ? children(isLoading) : children}
    </div>
  );
}
