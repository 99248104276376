import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation from "./translations/de/translation.json";

export const defaultNamespace = "translation";
export const resources = {
  de: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "de",
  ns: ["translation"],
  defaultNS: defaultNamespace,
  resources,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
