export type Geschlecht = "MALE" | "FEMALE" | "UNKNOWN";

/**
 * Ueber Actions werden die Zugriffsberechtigungen abgebildet.
 *
 * Jedem Benutzer kann eine Menge an Actions zugewiesen werden, fuer welche er
 * die Berechtigung hat. Anhand dieser `allowedActions` entscheidet die App, ob
 * Komponenten gerendert werden sollen und der Zugriff auf Routen erlaubt ist.
 */
export enum Action {
  MANAGE_USERS = "MANAGE_USERS",
  MANAGE_OTHER_CUSTOMERS = "MANAGE_OTHER_CUSTOMERS",
  EDIT_OWN_PROFILE = "EDIT_OWN_PROFILE",
  VIEW_ARTICLES = "VIEW_ARTICLES",
  VIEW_OTHER_ARTICLES = "VIEW_OTHER_ARTICLES",
  VIEW_ORDERS = "VIEW_ORDERS",
  EDIT_ORDERS = "EDIT_ORDERS",
  VIEW_NOTIFICATIONS = "VIEW_NOTIFICATIONS",
  VIEW_EDITORIAL_CALENDAR = "VIEW_EDITORIAL_CALENDAR",
  VIEW_USAGE_STATISTICS = "VIEW_USAGE_STATISTICS",
  // die beiden IT-Actions haben aktuell keinen Effekt im UI
  READ_IT_INFO = "READ_IT_INFO",
  MANAGE_IT_OPERATIONS = "MANAGE_IT_OPERATIONS",
}

export type BenutzerRolle =
  | "ROLE_IFA_ADMIN"
  | "ROLE_IFA_IT"
  | "ROLE_CUSTOMER_ADMIN"
  | "ROLE_CUSTOMER_USER"
  | "ROLE_CUSTOMER_INFOUSER";

class User {
  constructor(
    public allowedActions: Action[],
    public benutzerkennung: string,
    public email: string,
    public firmenname: string,
    public geschlecht: Geschlecht,
    public id: number,
    public kundennr: string,
    public kundenAdrnr: string,
    public nachname: string,
    public sprache: string,
    public vorname: string,
    public rolle: BenutzerRolle,
    public manageUsersAvailableRoles: BenutzerRolle[],
    public zuletztAngemeldet?: string | null,
    public telefonnummer?: string,
    public titel?: string | null,
    public sperre?: {
      grund: string;
      angelegt: string;
      angelegtVon: string;
    } | null,
    public nutzungsbedingungenAkzeptiert?: boolean,
    public neueEmail?: {
      email: string;
      linkAngelegt: string /* ISO Format*/;
      linkNutzbarBis: string /* ISO Format */;
    } | null
  ) {}

  static mock() {
    return new User(
      [Action.MANAGE_USERS, Action.EDIT_OWN_PROFILE],
      "ifaadm",
      "ifa@example.com",
      "Firma",
      "UNKNOWN",
      1,
      "1",
      "11111",
      "Nachname",
      "de",
      "Vorname",
      "ROLE_IFA_ADMIN",
      [],
      "2020-11-27T09:27:09.195Z",
      "+496990009321"
    );
  }
}

export default User;
