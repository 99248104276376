import React from "react";
import {
  TeachingId,
  teachingPriority,
} from "./components/TeachingBubbleTrigger";

type TeachingBubblePrioritizationProviderProps = { children: React.ReactNode };

type State = {
  requestShow: (teachingId: TeachingId) => void;
  requestHide: (teachingId: TeachingId) => void;
  currentTeachingId: TeachingId | undefined;
};

const TeachingBubblePrioritizationContext = React.createContext<
  State | undefined
>(undefined);

/**
 * Stellt einen React Context bereit, der die in Unterkomponenten gewünschten TeachingBubbles
 * verwaltet, und die Information liefert, welche von diesen am höchsten priorisiert ist.
 * Damit kann die gleichzeite Darstellung von mehreren TeachingBubbles bzw. die zufällige
 * Auswahl durch die Implementierung von FluentUI verhindert werden.
 */
function TeachingBubblePrioritizationProvider({
  children,
}: TeachingBubblePrioritizationProviderProps) {
  const [currentTeachingId, setCurrentTeachingId] = React.useState<
    TeachingId | undefined
  >(undefined);
  const [requestedTeachingIds, setRequestedTeachingIds] = React.useState<
    TeachingId[]
  >([]);

  const requestShow = React.useCallback((teachingId: TeachingId) => {
    setRequestedTeachingIds((state) => {
      if (state.indexOf(teachingId) >= 0) {
        return state;
      }

      const newState = [...state, teachingId].sort(
        (a, b) => teachingPriority.indexOf(a) - teachingPriority.indexOf(b)
      );
      return newState;
    });
  }, []);

  const requestHide = React.useCallback((teachingId: TeachingId) => {
    setRequestedTeachingIds((state) => {
      const idx = state.indexOf(teachingId);
      if (state.indexOf(teachingId) < 0) {
        return state;
      }
      const newState = [...state];
      newState.splice(idx, 1);
      return newState;
    });
  }, []);

  const value = React.useMemo(
    () => ({
      requestShow,
      requestHide,
      currentTeachingId,
    }),
    [requestShow, requestHide, currentTeachingId]
  );

  // Wir verzögern die Reaktion auf die Änderung von requestedTeachingIds, weil sich sonst Flackereffekte ergeben.
  // Beispiel: Eine hoch priorisierte Bubble wie "Mein Auftrag" wird geschlossen weil umnavigiert wird. Dann soll
  // nicht eine niedriger priorisierte Bubble angezeigt werden, die kurz danach wegen der Umnavigation unmounted wird.
  // Durch die kurze Verzögerung erreichen wir, dass zuerst die Umnavigation stattfindet und die niedriger priorisiert
  // Bubble dadurch auch unmounted wird.
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTeachingId(
        requestedTeachingIds.length > 0 ? requestedTeachingIds[0] : undefined
      );
    }, 150);
    return () => {
      clearTimeout(timeout);
    };
  }, [requestedTeachingIds]);

  return (
    <TeachingBubblePrioritizationContext.Provider value={value}>
      {children}
    </TeachingBubblePrioritizationContext.Provider>
  );
}

function useTeachingBubblePrioritization(teachingId: TeachingId) {
  const context = React.useContext(TeachingBubblePrioritizationContext);

  if (context === undefined) {
    throw new Error(
      "useTeachingBubblePrioritization must be used within a TeachingBubblePrioritizationProvider"
    );
  }

  const { requestShow, requestHide } = context;

  const requestDisplayState = React.useCallback(
    (show: boolean) => {
      if (show) {
        requestShow(teachingId);
      } else {
        requestHide(teachingId);
      }
    },
    [teachingId, requestShow, requestHide]
  );

  return {
    requestDisplayState,
    shouldDisplay: context.currentTeachingId === teachingId,
  };
}

export {
  TeachingBubblePrioritizationProvider,
  useTeachingBubblePrioritization,
};
