import {
  ServerValidationBody,
  ServerValidationFieldMessage,
} from "./FetchFactory";

export class Success<T> {
  readonly type = "Success";
  data: T;

  constructor(data: T) {
    this.data = data;
  }
}

export type ValidationFailureMessages<T> = Partial<Record<keyof T, string[]>>;

export class CompoundValidationFailure<T> {
  readonly type = "CompoundValidationFailure";

  constructor(readonly failures: Record<string, ValidationFailure<T>>) {}

  static isCompound(serverValidationBody: any): boolean {
    return (
      serverValidationBody &&
      !serverValidationBody.hasOwnProperty("fieldErrors") &&
      !serverValidationBody.hasOwnProperty("fieldWarnings") &&
      !serverValidationBody.hasOwnProperty("globalErrors") &&
      !serverValidationBody.hasOwnProperty("globalWarnings")
    );
  }

  static fromServerValidationBodies<T>(
    serverValidationBodies: Record<string, ServerValidationBody<T>>
  ): CompoundValidationFailure<T> {
    let failures: Record<string, ValidationFailure<T>> = {};
    for (const [key, value] of Object.entries(serverValidationBodies)) {
      failures[key] = ValidationFailure.fromServerValidationBody(value);
    }

    return new CompoundValidationFailure<T>(failures);
  }

  hasAnyWarnings() {
    for (const validationFailure of Object.values(this.failures)) {
      if (
        (validationFailure.globalWarnings &&
          validationFailure.globalWarnings.length > 0) ||
        (validationFailure.warnings &&
          Object.keys(validationFailure.warnings).length > 0)
      ) {
        return true;
      }
    }

    return false;
  }
}

export class ValidationFailure<T> {
  readonly type = "ValidationFailure";

  constructor(
    readonly errors?: ValidationFailureMessages<T>,
    readonly globalErrors?: string[],
    readonly warnings?: ValidationFailureMessages<T>,
    readonly globalWarnings?: string[]
  ) {}

  static fromServerValidationBody<T>(
    serverValidationBody: ServerValidationBody<T>
  ): ValidationFailure<T> {
    function mapMessages(
      messages: ServerValidationFieldMessage<T>[] | undefined
    ) {
      return messages?.reduce<ValidationFailureMessages<T>>(
        (carry, fieldError) => ({
          ...carry,
          [fieldError.fieldName]: [
            ...(carry[fieldError.fieldName] ?? []),
            fieldError.message,
          ],
        }),
        {}
      );
    }

    return new ValidationFailure(
      mapMessages(serverValidationBody.fieldErrors),
      serverValidationBody.globalErrors,
      mapMessages(serverValidationBody.fieldWarnings),
      serverValidationBody.globalWarnings
    );
  }
}

export class UnauthorizedFailure {
  readonly type = "UnauthorizedFailure";
}

export class ForbiddenFailure {
  readonly type = "ForbiddenFailure";
}

export class NotFoundFailure {
  readonly type = "NotFoundFailure";
  message?: string;

  constructor(message?: string) {
    this.message = message;
  }
}

export class GoneFailure {
  readonly type = "GoneFailure";
}

export class ConflictFailure {
  readonly type = "ConflictFailure";
  message?: string;

  constructor(message?: string) {
    this.message = message;
  }
}

export class BadGatewayFailure {
  readonly type = "BadGatewayFailure";
}

export class Failure {
  readonly type = "Failure";
}

type ApiResponse<T, E = {}> =
  | Success<T>
  | CompoundValidationFailure<E>
  | ValidationFailure<E>
  | UnauthorizedFailure
  | ForbiddenFailure
  | NotFoundFailure
  | GoneFailure
  | ConflictFailure
  | BadGatewayFailure
  | Failure;

export default ApiResponse;
