import React from "react";
import { useTranslation } from "react-i18next";
import {
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
  Location,
} from "react-router-dom";

function promptBeforeUnload(event: BeforeUnloadEvent) {
  // Cancel the event.
  event.preventDefault();
  // Chrome (and legacy IE) requires returnValue to be set.
  event.returnValue = "";
}

type Args = {
  isBlocked: boolean;
  onProceed?: (location: Location) => void;
  onCancel?: () => void;
};

export const usePromptBefore = ({ isBlocked, onCancel, onProceed }: Args) => {
  const { t } = useTranslation();
  const message = t("article.discardUnsavedChanges");
  const blockerCallback = React.useCallback<BlockerFunction>(
    (event) => {
      if (isBlocked) {
        const proceed = window.confirm(message);
        proceed ? onProceed?.(event.nextLocation) : onCancel?.();
        return !proceed;
      } else {
        onProceed?.(event.nextLocation);
        return false;
      }
    },
    [isBlocked, message, onCancel, onProceed]
  );
  const blocker = useBlocker(blockerCallback);

  React.useEffect(() => {
    if (blocker.state === "blocked" && !isBlocked) {
      blocker.reset();
    }
  }, [blocker, isBlocked]);

  React.useEffect(() => {
    if (!isBlocked) return;

    window.addEventListener("beforeunload", promptBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", promptBeforeUnload);
    };
  }, [isBlocked]);
};
