import { Navigate, useLocation } from "react-router-dom";

import { useAuthState } from "../authContext";
import { Action } from "../models/User";

type PrivateRouteProps = {
  /**
   * Erlaubt den Zugriff auf diese Route nur fuer Benutzer mit dieser Acion
   */
  requiredAction?: Action[];
  children: React.ReactNode;
};

export default function PrivateRoute({
  children,
  requiredAction,
}: PrivateRouteProps) {
  const auth = useAuthState();
  const location = useLocation();

  /**
   * Ueberprueft ob ein Profil vorhanden ist (der Benutzer gegenueber der API
   * authentifiziert ist) und der Benutzer die benoetigte Action hat.
   */
  function isAllowed() {
    const { profile } = auth;

    if (!profile) {
      return false;
    }

    return (
      !requiredAction ||
      requiredAction.length < 1 ||
      requiredAction.some((action) => profile.allowedActions.includes(action))
    );
  }

  return isAllowed() ? (
    <>{children}</>
  ) : (
    <Navigate to="/de" state={{ from: location }} replace />
  );
}
