import {
  Link as FluentLink,
  PrimaryButton,
  Text,
  Theme,
  mergeStyleSets,
  useTheme,
} from "@fluentui/react";
import { useAuthState } from "authContext";
import Card from "components/Card";
import Layout from "components/Layout";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";

const NEWLINE = "%0D%0A";

function getClassNames(theme: Theme) {
  return mergeStyleSets({
    container: {
      alignItems: "center",
      backgroundColor: theme.palette.themeLighterAlt,
      display: "flex",
      flexDirection: "column",
      padding: "2rem 4rem 3rem 4rem",
      margin: "0 1rem",
    },
    loginButton: {
      marginTop: "1rem",
    },
    mailButton: { marginTop: "2rem" },
    message: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
    },
    text: { marginTop: "1rem" },
    textContainer: {
      display: "flex",
    },
  });
}

export default function ErrorScreen() {
  const { t } = useTranslation();
  const auth = useAuthState();
  const theme = useTheme();
  const routeError = useRouteError();
  const styles = getClassNames(theme);

  const subheading = useMemo(() => {
    if (routeError instanceof Error) {
      return routeError.message;
    }

    if (isRouteErrorResponse(routeError)) {
      return routeError.statusText;
    }
  }, [routeError]);

  const stacktrace = useMemo(() => {
    if (routeError instanceof Error) {
      return routeError.stack;
    }

    if (isRouteErrorResponse(routeError)) {
      return routeError.data?.message;
    }
  }, [routeError]);

  const mailBody = encodeURIComponent(
    t("errorScreen.mail.body", {
      kundennr: auth.profile?.kundennr,
      username: auth.profile?.benutzerkennung,
      location: window.location,
      stacktrace,
    })
  ).replace("%0A", NEWLINE);

  const mailtoLink = `mailto:ifa@ifaffm.de?subject=${t(
    "errorScreen.mail.subject",
    { context: subheading }
  )}&body=${mailBody}`;

  return (
    <Layout center>
      <Card
        heading={t("errorScreen.title")}
        maxWidth={"1080px"}
        contentMarginTop={"1.5rem"}
      >
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <div className={styles.message}>
              <Trans i18nKey={"errorScreen.message"}>
                <Text className={styles.text} />
              </Trans>
              <FluentLink
                href={mailtoLink}
                target="_blank"
                rel="noreferrer"
                className={styles.mailButton}
              >
                {t("errorScreen.mailButton")}
              </FluentLink>
            </div>
          </div>
        </div>
        <Link to="/" className={styles.loginButton}>
          <PrimaryButton>{t("errorScreen.button")}</PrimaryButton>
        </Link>
      </Card>
    </Layout>
  );
}
