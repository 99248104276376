import ArtikelAttributModifikationDTO from "./ArtikelAttributModifikationDTO";
import HeldOrderDTO from "./HeldOrderDTO";
import PublikationsSpezifikationDTO from "./PublikationsSpezifikationDTO";

export enum AuftragStatus {
  KEIN_AUFTRAG = "KEIN_AUFTRAG",
  AUFTRAG = "AUFTRAG",
  IN_BEARBEITUNG = "IN_BEARBEITUNG",
}

// Durchgängige Nutzung von Auftragsart und Leistung
// https://ifaffm.atlassian.net/browse/PORTAL-690

export enum AuftragsArt {
  ARTIKEL_AENDERUNG = "ARTIKEL_AENDERUNG",
  ARTIKEL_NEUAUFNAHME = "ARTIKEL_NEUAUFNAHME",
  PZN_UEBERTRAG = "PZN_UEBERTRAG",
  ZUTEILUNG_NEUAUFNAHME = "ZUTEILUNG_NEUAUFNAHME",
  ZUTEILUNG_AENDERUNG = "ZUTEILUNG_AENDERUNG",
  ZUTEILUNG_UEBERTRAG = "ZUTEILUNG_UEBERTRAG",
}

export enum Leistung {
  ARTIKEL_AENDERUNG = "ARTÄND",
  ARTIKEL_NEUAUFNAHME = "ARTNEU",
  ZUTEILUNG_AENDERUNG = "ZUTÄND",
  ZUTEILUNG_NEUAUFNAHME = "ZUTNEU",
}

export interface AuftragVerarbeitung {
  fortschritt?: number;
  unterbrechbar?: boolean;
  fehlerMeldung?: string;
}

export interface Auftrag {
  anzahlPositionen: number;
  valid: boolean;
  status: AuftragStatus;
  verarbeitung?: AuftragVerarbeitung;
  publikationsSpezifikation?: PublikationsSpezifikationDTO;
  auftragsArt: AuftragsArt;
}

export default interface AuftragStatusDTO extends Auftrag {
  modifikationen: ArtikelAttributModifikationDTO[];
  heldOrders: HeldOrderDTO[];
}
