import {
  IMessageBarProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  useTheme,
} from "@fluentui/react";
import React from "react";

type ShadowMessageBarProps = {
  isVisible?: boolean;
  type?: MessageBarType;
};

function getClassNames() {
  return mergeStyleSets({
    dismissIcon: {
      height: "auto",

      "& .ms-Icon": {
        alignSelf: "flex-start",
        // Dieser Wert setzt die Icon-Größe
        fontSize: "1rem",
        height: "1rem",
      },
    },
    messageBar: {
      borderRadius: "4px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
      justifyContent: "center",
      marginTop: 8,
      minHeight: 36,
    },
    messageLabel: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.3rem",
    },
    hidden: {
      display: "none",
    },
  });
}

export default function GlobalMessageBar({
  children,
  isVisible = true,
  onDismiss,
  type,
}: React.PropsWithChildren<
  ShadowMessageBarProps & Pick<IMessageBarProps, "onDismiss">
>) {
  const theme = useTheme();
  const styles = getClassNames();
  return (
    <MessageBar
      className={`${styles.messageBar} ${isVisible ? "" : styles.hidden}`}
      messageBarType={type}
      onDismiss={onDismiss}
      styles={{
        icon: {
          lineHeight: "1rem",
          color:
            type === MessageBarType.warning ? theme.palette.yellow : undefined,
        },
        dismissal: styles.dismissIcon,
        innerText: styles.messageLabel,
      }}
    >
      {children}
    </MessageBar>
  );
}
