import { FocusTrapZone, mergeStyleSets } from "@fluentui/react";

type ModalFrameProps = {
  children: React.ReactNode;
  isVisible: boolean;
  maxWidth: string;
  dataTestId?: string;
};

function getClassNames(maxWidth: string) {
  return mergeStyleSets({
    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(66, 66, 66, 0.5)",
      zIndex: 500,
    },
    modal: {
      position: "fixed",
      background: "white",
      width: "calc(100% - 2rem)",
      maxWidth,
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxHeight: "calc(100% - 2rem)",
      overflowY: "auto",
      transition: "max-width 250ms",
    },
    displayBlock: {
      display: "block",
    },
    displayNone: {
      display: "none",
    },
  });
}

function handleModalClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.stopPropagation();
}

export default function ModalFrame({
  children,
  isVisible,
  maxWidth,
  dataTestId = "@modal",
}: ModalFrameProps) {
  const styles = getClassNames(maxWidth);

  return (
    <div
      data-testid={dataTestId}
      className={`${styles.backdrop} ${
        isVisible ? styles.displayBlock : styles.displayNone
      }`}
    >
      <FocusTrapZone disabled={!isVisible}>
        <div className={styles.modal} onClick={handleModalClick}>
          {children}
        </div>
      </FocusTrapZone>
    </div>
  );
}
