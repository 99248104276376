import React from "react";
import { mergeStyleSets } from "@fluentui/react";

type InfoBarProps = {
  children: React.ReactNode;
};

function getClassNames() {
  return mergeStyleSets({
    infoBar: {
      display: "flex",
      justifyContent: "flex-start",
      "& > *": {
        marginLeft: "2rem",
      },
      "& > :first-child": {
        marginLeft: "0px",
      },
    },
  });
}

export default function InfoBar({ children }: InfoBarProps) {
  const styles = getClassNames();

  return <div className={styles.infoBar}>{children}</div>;
}
