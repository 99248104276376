import React from "react";
import { mergeStyleSets } from "@fluentui/react";

type InfoProps = {
  children: React.ReactNode;
};

function getClassNames() {
  return mergeStyleSets({
    info: {
      display: "flex",
      justifyContent: "flex-start",
      "& > *": {
        marginLeft: "0.5rem",
      },
      "& > :first-child": {
        marginLeft: "0rem",
      },
    },
  });
}

export default function Info({ children }: InfoProps) {
  const styles = getClassNames();

  return <div className={styles.info}>{children}</div>;
}
