export function formatQueryString(
  data: Record<string, string | undefined>,
  allowEmpty = false
) {
  return Object.entries(data)
    .filter<[string, string]>((entry): entry is [string, string] =>
      allowEmpty ? entry[1] !== undefined : !!entry[1]
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
}
