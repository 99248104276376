import React from "react";
import { Text } from "@fluentui/react";

type H1Props = { children: React.ReactNode };

export default function H1({ children, ...otherProps }: H1Props) {
  return (
    <Text as="h1" variant="xxLarge" block style={{ margin: 0 }} {...otherProps}>
      {children}
    </Text>
  );
}
