import AllocationNewDTO from "dto/AllocationNewDTO";
import AllocationUpdateDTO from "dto/AllocationUpdateDTO";
import AuftragReaktivierenDTO from "dto/AuftragReaktivierenDTO";
import AuftragZurueckstellenDTO from "dto/AuftragZurueckstellenDTO";
import BudiGeneratorRequestDTO from "dto/BudiGeneratorRequestDTO";
import CustomerValidityCheckRequestDTO from "dto/CustomerValidityCheckRequestDTO";
import GeneratorResultDTO from "dto/GeneratorResult";
import HpcGeneratorRequestDTO from "dto/HpcGeneratorRequestDTO";
import HypArchivDateiDTO from "dto/HypArchivDateiDTO";
import PortalBenutzerRegistrierungDTO from "dto/PortalBenutzerRegistrierungDTO";
import RegistrierungTokenInfoDTO from "dto/RegistrierungTokenInfoDTO";
import TelefonnummerUpdateDTO from "dto/TelefonnummerUpdateDTO";
import UsageStatisticsDTO from "dto/UsageStatisticsDTO";
import ZurueckgestellterAuftragDTO from "dto/ZurueckgestellterAuftragDTO";
import { ArticleFilterState } from "../components/ArticleFilter";
import { ArticleSearchFormFields } from "../components/ArticleSearchForm";
import { ChangePasswordFormFields } from "../components/ChangePasswordModal";
import { ReleaseModalFormFields } from "../components/ReleaseModal";
import { TermsOfUseGuardFormFields } from "../components/TermsOfUseGuard";
import { UserFormFields } from "../components/UserForm";
import AddNachweisDTO from "../dto/AddNachweisDTO";
import AepApuPriceRequestDTO from "../dto/AepApuPriceRequestDTO";
import AepApuPriceResultDTO from "../dto/AepApuPriceResultDTO";
import AepAvpPriceRequestDTO from "../dto/AepAvpPriceRequestDTO";
import AepAvpPriceResultDTO from "../dto/AepAvpPriceResultDTO";
import AllocationTransferDTO from "../dto/AllocationTransferDTO";
import ApuAvpPriceRequestDTO from "../dto/ApuAvpPriceRequestDTO";
import ApuAvpPriceResultDTO from "../dto/ApuAvpPriceResultDTO";
import ArtikelAttributMetaDataDTO from "../dto/ArtikelAttributMetaDataDTO";
import ArtikelDetailsDTO from "../dto/ArtikelDetailsDTO";
import ArtikelListeDTO from "../dto/ArtikelListeDTO";
import ArtikelTransferDTO from "../dto/ArtikelTransferDTO";
import ArtikelUpdateDTO from "../dto/ArtikelUpdateDTO";
import AssignNachweisDTO from "../dto/AssignNachweisDTO";
import AuftragDTO from "../dto/AuftragDTO";
import AuftragModifikationErgebnisDTO from "../dto/AuftragModifikationErgebnisDTO";
import AuftragStatusDTO, { AuftragsArt } from "../dto/AuftragStatusDTO";
import AuftragUeberstellenAnfrageDTO from "../dto/AuftragUeberstellenAnfrageDTO";
import AugmentedArtikelDetailAttributDTO from "../dto/AugmentedArtikelDetailAttributDTO";
import { BenutzerEinstellungenDTO } from "../dto/BenutzerEinstellungenDTO";
import { BenutzerSperreDTO } from "../dto/BenutzerSperreDTO";
import DashboardContentDTO from "../dto/DashboardContentDTO";
import DiffPpuApuErstattungRequestDTO from "../dto/DiffPpuApuErstattungRequestDTO";
import DiffPpuApuErstattungResultDTO from "../dto/DiffPpuApuErstattungResultDTO";
import FirstPublicationDTO from "../dto/FirstPublicationDTO";
import KalenderDTO from "../dto/KalenderDTO";
import NeueEmailTokenInfoDTO from "../dto/NeueEmailTokenInfoDTO";
import NotificationsResultDTO from "../dto/NotificationsResultDTO";
import OrderUpdateDTO from "../dto/OrderUpdateDTO";
import { PortalBenutzerDTO } from "../dto/PortalBenutzerDTO";
import PortalBenutzerEinladungenDTO from "../dto/PortalBenutzerEinladungenDTO";
import PortalBenutzerEinladungenErgebnisDTO from "../dto/PortalBenutzerEinladungenErgebnisDTO";
import PpuPriceRequestDTO from "../dto/PpuPriceRequestDTO";
import PpuPriceResultDTO from "../dto/PpuPriceResultDTO";
import SchluesselObjektWertDTO from "../dto/SchluesselObjektWertDTO";
import SelbstRegistrierungDTO from "../dto/SelbstRegistrierungDTO";
import SelbstRegistrierungsZustandDTO from "../dto/SelbstRegistrierungsZustandDTO";
import TokenInfoResultDTO from "../dto/TokenInfoResultDTO";
import ZuteilungListeDTO from "../dto/ZuteilungListeDTO";
import ZuteilungsDetailsDTO from "../dto/ZuteilungsDetailsDTO";
import { MessageContent } from "../messageContext";
import Customer from "../models/Customer";
import User from "../models/User";
import { ArticleFormFields } from "../modules/article/components/ArticleForm";
import { LoginFormFields } from "../modules/login/components/LoginForm";
import { NotificationFilterState } from "../modules/notifications/NotificationFilter";
import { ConfirmEmailFormFields } from "../screens/ConfirmEmail";
import { RequestResetPasswordFormFields } from "../screens/RequestResetPassword";
import { SetPasswordFormFields } from "../screens/SetPassword";
import { formatQueryString } from "../utils/http";
import ApiResponse from "./ApiResponse";
import FetchFactory from "./FetchFactory";

class Api {
  private static instance: Api;
  private static resetAuth: () => void;
  private static showMessage: (message: MessageContent) => void;

  static getInstance(config?: {
    resetAuth?: () => void;
    showMessage?: (message: MessageContent) => void;
  }) {
    if (!Api.instance) {
      Api.instance = new Api();
    }

    if (config?.resetAuth) {
      Api.resetAuth = config.resetAuth;
    }

    if (config?.showMessage) {
      Api.showMessage = config.showMessage;
    }

    return Api.instance;
  }

  resetAuth() {
    if (Api.resetAuth) {
      Api.resetAuth();
    }
  }

  showMessage(message: MessageContent) {
    if (Api.showMessage) {
      Api.showMessage(message);
    }
  }

  async getUsers(): Promise<ApiResponse<PortalBenutzerDTO[]>> {
    const url = "/api/users";
    return await FetchFactory.get<PortalBenutzerDTO[]>(this, url).fetch();
  }

  async getUser(id: number | string): Promise<ApiResponse<User>> {
    const url = `/api/users/${id}`;
    return await FetchFactory.get<User>(this, url).fetch();
  }

  async putUser(
    id: string | number,
    user: UserFormFields
  ): Promise<ApiResponse<User>> {
    const url = `/api/users/${id}`;
    return await FetchFactory.put<User, UserFormFields>(
      this,
      url,
      user
    ).fetch();
  }

  async postUser(
    user: UserFormFields
  ): Promise<ApiResponse<any, UserFormFields>> {
    const url = "/api/users";
    return await FetchFactory.post<User, UserFormFields>(
      this,
      url,
      user
    ).fetch();
  }

  async deleteUser(userId: string | number): Promise<ApiResponse<void>> {
    const url = `/api/users/${userId}`;
    return await FetchFactory.delete<void>(this, url).fetch();
  }

  async getUserInvitations(): Promise<
    ApiResponse<PortalBenutzerEinladungenDTO>
  > {
    const url = `/api/users/invitations`;
    return await FetchFactory.get<PortalBenutzerEinladungenDTO>(
      this,
      url
    ).fetch();
  }

  async postUserInvitations(
    userInvitations: PortalBenutzerEinladungenDTO
  ): Promise<ApiResponse<PortalBenutzerEinladungenErgebnisDTO>> {
    const url = `/api/users/invitations`;
    return await FetchFactory.post<
      PortalBenutzerEinladungenErgebnisDTO,
      PortalBenutzerEinladungenDTO
    >(this, url, userInvitations).fetch();
  }

  async getCustomers(
    kundennr: number | string
  ): Promise<ApiResponse<Customer[]>> {
    const url = `/api/customers?search=${kundennr}`;
    return await FetchFactory.get<Customer[]>(this, url).fetch();
  }

  async validateCustomerNumber(
    kundennr: string
  ): Promise<ApiResponse<void, CustomerValidityCheckRequestDTO>> {
    const url = "/api/customers/validity";
    const body: CustomerValidityCheckRequestDTO = {
      kundennr,
    };
    return await FetchFactory.post<void, CustomerValidityCheckRequestDTO>(
      this,
      url,
      body
    ).fetch();
  }

  async postLogin(
    username: string,
    password: string,
    kundennr: string
  ): Promise<ApiResponse<User, LoginFormFields>> {
    const url = "/api/login";
    const body = { username, password, kundennr };
    return await FetchFactory.post<User, LoginFormFields>(this, url, body)
      .ensureCsrfToken()
      .fetch();
  }

  async postLogout() {
    const url = "/api/logout";
    return await FetchFactory.post(this, url, {}).fetch();
  }

  async postSignUp(data: SelbstRegistrierungDTO) {
    const url = "/api/users/signup";
    return await FetchFactory.post<void, SelbstRegistrierungDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async getProfile(): Promise<ApiResponse<User>> {
    const url = "/api/users/profile";
    return await FetchFactory.get<User>(this, url).fetch();
  }

  async postProfile(data: UserFormFields): Promise<ApiResponse<User>> {
    const url = "/api/users/profile";
    return await FetchFactory.post<User, UserFormFields>(
      this,
      url,
      data
    ).fetch();
  }

  async getUserSettings(): Promise<ApiResponse<BenutzerEinstellungenDTO>> {
    const url = "/api/users/settings";
    return await FetchFactory.get<BenutzerEinstellungenDTO, void>(
      this,
      url
    ).fetch();
  }

  async postUserSettings(
    data: BenutzerEinstellungenDTO
  ): Promise<ApiResponse<BenutzerEinstellungenDTO>> {
    const url = "/api/users/settings";
    return await FetchFactory.post<
      BenutzerEinstellungenDTO,
      BenutzerEinstellungenDTO
    >(this, url, data).fetch();
  }

  async postTermsOfUse(
    data: TermsOfUseGuardFormFields
  ): Promise<ApiResponse<User>> {
    const url = "/api/users/profile/termsOfUse";
    return await FetchFactory.post<User, TermsOfUseGuardFormFields>(
      this,
      url,
      data
    ).fetch();
  }

  async postConfirmEmail(
    data: ConfirmEmailFormFields
  ): Promise<ApiResponse<void>> {
    const url = "/api/users/profile/confirmemail";
    return await FetchFactory.post<void, ConfirmEmailFormFields>(
      this,
      url,
      data
    ).fetch();
  }

  async postPassword(
    data: ChangePasswordFormFields
  ): Promise<ApiResponse<void, ChangePasswordFormFields>> {
    const url = "/api/users/profile/password";
    return await FetchFactory.post<void, ChangePasswordFormFields>(
      this,
      url,
      data
    ).fetch();
  }

  async postSetPassword(
    data: SetPasswordFormFields
  ): Promise<ApiResponse<void>> {
    const url = "/api/users/profile/passwordreset";
    return await FetchFactory.post<void, SetPasswordFormFields>(
      this,
      url,
      data
    ).fetch();
  }

  async postRequestResetPassword(
    data: RequestResetPasswordFormFields
  ): Promise<ApiResponse<void, RequestResetPasswordFormFields>> {
    const url = "/api/users/profile/passwordresetrequest";
    return await FetchFactory.post<void, RequestResetPasswordFormFields>(
      this,
      url,
      data
    )
      .ensureCsrfToken()
      .fetch();
  }

  async postSetPhoneNumber(
    telefonnummer: string
  ): Promise<ApiResponse<PortalBenutzerDTO, TelefonnummerUpdateDTO>> {
    const url = "/api/users/profile/phonenumber";
    return await FetchFactory.post<PortalBenutzerDTO, TelefonnummerUpdateDTO>(
      this,
      url,
      { telefonnummer }
    ).fetch();
  }

  async postRequestResetPasswordForUser(
    userId: string | number
  ): Promise<ApiResponse<void, void>> {
    const url = `/api/users/${userId}/passwordresetrequest`;
    return await FetchFactory.post<void, void>(this, url).fetch();
  }

  async validatePasswordResetToken(
    token: string
  ): Promise<ApiResponse<TokenInfoResultDTO>> {
    const url = `/api/users/token/${token}`;
    return await FetchFactory.get<TokenInfoResultDTO>(this, url).fetch();
  }

  async validateRegistrationToken(
    token: string
  ): Promise<ApiResponse<RegistrierungTokenInfoDTO>> {
    const url = `/api/users/registration/token/${token}`;
    return await FetchFactory.get<RegistrierungTokenInfoDTO>(this, url).fetch();
  }

  async validateConfirmEmailToken(
    token: string
  ): Promise<ApiResponse<NeueEmailTokenInfoDTO>> {
    const url = `/api/users/profile/confirmemail/${token}`;
    return await FetchFactory.get<NeueEmailTokenInfoDTO>(this, url).fetch();
  }

  async postRegistration(
    data: PortalBenutzerRegistrierungDTO
  ): Promise<ApiResponse<PortalBenutzerDTO, PortalBenutzerRegistrierungDTO>> {
    const url = `/api/users/registration`;
    return await FetchFactory.post<
      PortalBenutzerDTO,
      PortalBenutzerRegistrierungDTO
    >(this, url, data).fetch();
  }

  async postLockForUser(
    userId: string | number,
    data: BenutzerSperreDTO
  ): Promise<ApiResponse<User, BenutzerSperreDTO>> {
    const url = `/api/users/${userId}/lock`;
    return await FetchFactory.post<User, BenutzerSperreDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async deleteLockForUser(
    userId: string | number
  ): Promise<ApiResponse<User, void>> {
    const url = `/api/users/${userId}/lock`;
    return await FetchFactory.delete<User>(this, url).fetch();
  }

  async getDashboardContent(): Promise<ApiResponse<DashboardContentDTO, void>> {
    const url = "/api/dashboard";
    return await FetchFactory.get<DashboardContentDTO>(this, url).fetch();
  }

  async getUsageStatistics(
    start?: string,
    end?: string
  ): Promise<ApiResponse<UsageStatisticsDTO, void>> {
    const query = formatQueryString({ start, end });
    const url = `/api/statistics?${query}`;
    return await FetchFactory.get<UsageStatisticsDTO>(this, url).fetch();
  }

  async getAllocations(
    data: ArticleSearchFormFields,
    filter?: ArticleFilterState
  ): Promise<ApiResponse<ZuteilungListeDTO>> {
    let apiFilter = {};
    if (filter) {
      apiFilter = Object.fromEntries(
        Object.entries(filter.filter).map(([k, v]) => [k, v.data])
      );
    }

    const query = (filter ? [data, apiFilter, filter.sort] : [data])
      .map((params) => formatQueryString(params, true))
      .filter((str) => !!str)
      .join("&");
    const url = `/api/articles/allocations?${query}`;
    return await FetchFactory.get<ZuteilungListeDTO, ArticleSearchFormFields>(
      this,
      url
    ).fetch();
  }

  async getAllocation(
    allocationId: string
  ): Promise<
    ApiResponse<ZuteilungsDetailsDTO<AugmentedArtikelDetailAttributDTO>>
  > {
    const url = `/api/articles/zuteilung/${allocationId}`;
    return await FetchFactory.get<
      ZuteilungsDetailsDTO<AugmentedArtikelDetailAttributDTO>
    >(this, url).fetch();
  }

  async getArticles(
    data: ArticleSearchFormFields,
    filter: ArticleFilterState
  ): Promise<ApiResponse<ArtikelListeDTO>> {
    const apiFilter = Object.fromEntries(
      Object.entries(filter.filter).map(([k, v]) => [k, v.data])
    );
    const queryString = [data, apiFilter, filter.sort]
      .map((params) => formatQueryString(params, true))
      .filter((str) => !!str)
      .join("&");
    const url = `/api/articles?${queryString}`;
    return await FetchFactory.get<ArtikelListeDTO, ArticleSearchFormFields>(
      this,
      url
    ).fetch();
  }

  async getArticlesMeta(): Promise<ApiResponse<ArtikelAttributMetaDataDTO>> {
    const url = "/api/articles/meta";
    return await FetchFactory.get<ArtikelAttributMetaDataDTO>(
      this,
      url
    ).fetch();
  }

  async getSignUpRequest(
    signUpRequestId: number | string
  ): Promise<ApiResponse<SelbstRegistrierungsZustandDTO>> {
    const url = `/api/users/signup/${signUpRequestId}`;
    return await FetchFactory.get<SelbstRegistrierungsZustandDTO>(
      this,
      url
    ).fetch();
  }

  async postSignUpRequestAccept(
    signUpRequestId: number | string,
    signUpRequest: SelbstRegistrierungDTO
  ) {
    const url = `/api/users/signup/${signUpRequestId}/accept`;
    return await FetchFactory.post<void, SelbstRegistrierungDTO>(
      this,
      url,
      signUpRequest
    ).fetch();
  }

  async postSignUpRequestReject(signUpRequestId: number | string) {
    const url = `/api/users/signup/${signUpRequestId}/refuse`;
    return await FetchFactory.post<void, void, ArticleFormFields>(
      this,
      url
    ).fetch();
  }

  async getTitles(): Promise<ApiResponse<SchluesselObjektWertDTO[]>> {
    const url = "/api/articles/meta/titles";
    return await FetchFactory.get<SchluesselObjektWertDTO[]>(this, url).fetch();
  }

  async getArticle(
    articleId: string
  ): Promise<
    ApiResponse<ArtikelDetailsDTO<AugmentedArtikelDetailAttributDTO>>
  > {
    const url = `/api/articles/${articleId}`;
    return await FetchFactory.get<
      ArtikelDetailsDTO<AugmentedArtikelDetailAttributDTO>
    >(this, url).fetch();
  }

  async getOrderPosition(
    positionId: number
  ): Promise<
    ApiResponse<ArtikelDetailsDTO<AugmentedArtikelDetailAttributDTO>>
  > {
    const url = `/api/orders/position/${positionId}`;
    return await FetchFactory.get<
      ArtikelDetailsDTO<AugmentedArtikelDetailAttributDTO>
    >(this, url).fetch();
  }

  async getCalendar(orderType: AuftragsArt): Promise<ApiResponse<KalenderDTO>> {
    const url = `/api/orders/calendar?auftragsArt=${orderType}`;
    return await FetchFactory.get<KalenderDTO>(this, url).fetch();
  }

  async validateAndPostArticle(
    articleId: string,
    data: ArticleFormFields,
    release: ReleaseModalFormFields,
    validateOnly: boolean,
    ignoreWarnings: boolean
  ): Promise<ApiResponse<AuftragModifikationErgebnisDTO, ArticleFormFields>> {
    const body: ArtikelUpdateDTO = {
      artid: articleId,
      vdatum: release.vDate,
      sperrfrist: release.blockingPeriod === 1,
      attribute: Object.entries(data).map(([key, value]) => ({
        attributName: key,
        attributWert: value || "",
      })),
    };
    const url = validateOnly
      ? "/api/orders/article/validate"
      : `/api/orders/article${ignoreWarnings ? "?ignoreWarnings=true" : ""}`;
    return await FetchFactory.post<
      AuftragModifikationErgebnisDTO,
      ArtikelUpdateDTO,
      ArticleFormFields
    >(this, url, body).fetch();
  }

  async validateAndPostFirstPublication(
    articleId: string | null,
    positionId: number | null,
    data: ArticleFormFields,
    release: ReleaseModalFormFields,
    validateOnly: boolean,
    ignoreWarnings: boolean
  ): Promise<ApiResponse<AuftragModifikationErgebnisDTO, ArticleFormFields>> {
    const body: FirstPublicationDTO = {
      artid: articleId,
      positionId,
      vdatum: release.vDate,
      sperrfrist: release.blockingPeriod === 1,
      attribute: Object.entries(data).map(([key, value]) => ({
        attributName: key,
        attributWert: value || "",
      })),
    };
    const url = validateOnly
      ? "/api/orders/first-publication/validate"
      : `/api/orders/first-publication${
          ignoreWarnings ? "?ignoreWarnings=true" : ""
        }`;
    return await FetchFactory.post<
      AuftragModifikationErgebnisDTO,
      FirstPublicationDTO,
      ArticleFormFields
    >(this, url, body).fetch();
  }

  async validateAndPostAllocation(
    allocationId: string,
    data: ArticleFormFields,
    validateOnly: boolean,
    ignoreWarnings: boolean
  ): Promise<ApiResponse<AuftragModifikationErgebnisDTO, ArticleFormFields>> {
    const body: AllocationUpdateDTO = {
      artid: allocationId,
      attribute: Object.entries(data).map(([key, value]) => ({
        attributName: key,
        attributWert: value || "",
      })),
    };
    const url = validateOnly
      ? "/api/orders/allocation/validate"
      : `/api/orders/allocation${ignoreWarnings ? "?ignoreWarnings=true" : ""}`;
    return await FetchFactory.post<
      AuftragModifikationErgebnisDTO,
      AllocationUpdateDTO,
      ArticleFormFields
    >(this, url, body).fetch();
  }

  async validateAndPostNewAllocation(
    positionId: number | null,
    data: ArticleFormFields,
    validateOnly: boolean,
    ignoreWarnings: boolean
  ): Promise<ApiResponse<AuftragModifikationErgebnisDTO, ArticleFormFields>> {
    const body: AllocationNewDTO = {
      positionId,
      attribute: Object.entries(data).map(([key, value]) => ({
        attributName: key,
        attributWert: value || "",
      })),
    };
    const url = validateOnly
      ? "/api/orders/new-allocation/validate"
      : `/api/orders/new-allocation${
          ignoreWarnings ? "?ignoreWarnings=true" : ""
        }`;

    return await FetchFactory.post<
      AuftragModifikationErgebnisDTO,
      AllocationNewDTO,
      ArticleFormFields
    >(this, url, body).fetch();
  }

  async postArticleTransfer(
    kundennr: string,
    release: ReleaseModalFormFields,
    articleIds: string[]
  ): Promise<ApiResponse<AuftragStatusDTO, ArtikelTransferDTO>> {
    const url = `/api/orders/article/supplier`;
    const body = {
      vdatum: release.vDate,
      sperrfrist: release.blockingPeriod === 1,
      kundennr,
      artids: articleIds,
    };
    return await FetchFactory.post<AuftragStatusDTO, ArtikelTransferDTO>(
      this,
      url,
      body
    ).fetch();
  }

  async postAllocationTransfer(
    kundennr: string,
    articleIds: string[]
  ): Promise<ApiResponse<AuftragStatusDTO, AllocationTransferDTO>> {
    const url = `/api/orders/allocation/supplier`;
    const body = {
      kundennr,
      artids: articleIds,
    };
    return await FetchFactory.post<AuftragStatusDTO, AllocationTransferDTO>(
      this,
      url,
      body
    ).fetch();
  }

  async getAuftrag(): Promise<ApiResponse<AuftragDTO>> {
    const url = `/api/orders`;
    return await FetchFactory.get<AuftragDTO>(this, url).fetch();
  }

  async putAuftrag(
    data: OrderUpdateDTO
  ): Promise<ApiResponse<AuftragDTO, OrderUpdateDTO>> {
    const url = "/api/orders";
    return await FetchFactory.put<AuftragDTO, OrderUpdateDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async deleteAuftrag(): Promise<ApiResponse<void, void>> {
    const url = `/api/orders`;
    return await FetchFactory.delete<void>(this, url).fetch();
  }

  async getAuftragStatus(): Promise<ApiResponse<AuftragStatusDTO>> {
    const url = "/api/orders/status";
    return await FetchFactory.get<AuftragStatusDTO>(this, url)
      .suppressMessages()
      .fetch();
  }

  async deleteAuftragPosition(
    positionId: number
  ): Promise<ApiResponse<void, void>> {
    const url = `/api/orders/position/${positionId}`;
    return await FetchFactory.delete<void>(this, url).fetch();
  }

  async uploadProofDocument(
    file: File
  ): Promise<ApiResponse<HypArchivDateiDTO, FormData>> {
    const url = "/api/orders/proof/files";
    const data = new FormData();
    data.set("nachweisDatei", file, file.name);

    return await FetchFactory.postFormData<HypArchivDateiDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async getProofDocuments(): Promise<ApiResponse<HypArchivDateiDTO[], void>> {
    const url = "/api/orders/proof/files";

    return await FetchFactory.get<HypArchivDateiDTO[]>(this, url).fetch();
  }

  async deleteProofDocument(
    nachweisId: number
  ): Promise<ApiResponse<AuftragDTO, void>> {
    const url = `/api/orders/proof/files/${nachweisId}`;
    return await FetchFactory.delete<AuftragDTO>(this, url).fetch();
  }

  async mapFilesToProofDocumentTypes(data: AssignNachweisDTO) {
    const url = "/api/orders/proof/";
    return await FetchFactory.post<AuftragDTO, AssignNachweisDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async deleteProofAssignment(
    nachweisId: number
  ): Promise<ApiResponse<AuftragDTO, void>> {
    const url = `/api/orders/proof/${nachweisId}`;
    return await FetchFactory.delete<AuftragDTO>(this, url).fetch();
  }

  // TODO: Entferne diesen Endpunkt
  async assignRemoteProofDocumentToPosition(
    positionId: number,
    documentId: number,
    nachweisTyp: string,
    assignRemaining = false
  ): Promise<ApiResponse<AuftragDTO>> {
    const data: AddNachweisDTO = {
      nachweisTyp,
      documentId,
      assignRemaining,
    };
    const url = `/api/orders/proof/position/${positionId}`;
    return await FetchFactory.post<AuftragDTO, AddNachweisDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async submitOrder(
    data: AuftragUeberstellenAnfrageDTO
  ): Promise<ApiResponse<AuftragStatusDTO, AuftragUeberstellenAnfrageDTO>> {
    const url = `/api/orders`;
    return await FetchFactory.post<
      AuftragStatusDTO,
      AuftragUeberstellenAnfrageDTO
    >(this, url, data).fetch();
  }

  async holdOrder(
    data: AuftragZurueckstellenDTO
  ): Promise<ApiResponse<void, AuftragZurueckstellenDTO>> {
    const url = `/api/orders/hold`;
    return await FetchFactory.put<void, AuftragZurueckstellenDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async reactivateHeldOrder(
    data: AuftragReaktivierenDTO
  ): Promise<ApiResponse<AuftragDTO, AuftragReaktivierenDTO>> {
    const url = `/api/orders/reactivate`;
    return await FetchFactory.put<AuftragDTO, AuftragReaktivierenDTO>(
      this,
      url,
      data
    ).fetch();
  }

  async getZurueckgestellteAuftraege(): Promise<
    ApiResponse<ZurueckgestellterAuftragDTO[]>
  > {
    const url = `/api/orders/held`;
    return await FetchFactory.get<ZurueckgestellterAuftragDTO[]>(
      this,
      url
    ).fetch();
  }

  async cancelOrder(): Promise<ApiResponse<AuftragStatusDTO>> {
    const url = "/api/orders/job/interrupt";

    return await FetchFactory.post<AuftragStatusDTO, void>(this, url).fetch();
  }

  async determineAepAvpPrice(
    data: AepAvpPriceRequestDTO
  ): Promise<ApiResponse<AepAvpPriceResultDTO>> {
    const url = `/api/articles/price/aep_avp`;
    return await FetchFactory.post<
      AepAvpPriceResultDTO,
      AepAvpPriceRequestDTO,
      void
    >(this, url, data).fetch();
  }

  async determineAepApuPrice(
    data: AepApuPriceRequestDTO
  ): Promise<ApiResponse<AepApuPriceResultDTO>> {
    const url = `/api/articles/price/aep_apu`;
    return await FetchFactory.post<
      AepApuPriceResultDTO,
      AepApuPriceRequestDTO,
      void
    >(this, url, data).fetch();
  }

  async determineApuAvpPrice(
    data: ApuAvpPriceRequestDTO
  ): Promise<ApiResponse<ApuAvpPriceResultDTO>> {
    const url = `/api/articles/price/apu_avp`;
    return await FetchFactory.post<
      ApuAvpPriceResultDTO,
      ApuAvpPriceRequestDTO,
      void
    >(this, url, data).fetch();
  }

  async determinePpuPrice(
    data: PpuPriceRequestDTO
  ): Promise<ApiResponse<PpuPriceResultDTO>> {
    const url = `/api/articles/price/ppu`;
    return await FetchFactory.post<PpuPriceResultDTO, PpuPriceRequestDTO, void>(
      this,
      url,
      data
    ).fetch();
  }

  async determinePpuApuDiff(
    data: DiffPpuApuErstattungRequestDTO
  ): Promise<ApiResponse<DiffPpuApuErstattungResultDTO>> {
    const url = `/api/articles/price/diff_ppu_apuerstattung`;
    return await FetchFactory.post<
      DiffPpuApuErstattungResultDTO,
      DiffPpuApuErstattungRequestDTO,
      void
    >(this, url, data).fetch();
  }

  async getNotifications(
    filter: NotificationFilterState
  ): Promise<ApiResponse<NotificationsResultDTO>> {
    const queryString = Object.entries(filter)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const url = `/api/notifications?${queryString}`;
    return await FetchFactory.get<NotificationsResultDTO>(this, url).fetch();
  }

  async generateHpc(
    data: HpcGeneratorRequestDTO
  ): Promise<ApiResponse<GeneratorResultDTO>> {
    const url = `/api/articles/generator/hpc`;
    return await FetchFactory.post<
      GeneratorResultDTO,
      HpcGeneratorRequestDTO,
      void
    >(this, url, data).fetch();
  }

  async generateBudi(
    data: BudiGeneratorRequestDTO
  ): Promise<ApiResponse<GeneratorResultDTO>> {
    const url = `/api/articles/generator/budi`;
    return await FetchFactory.post<
      GeneratorResultDTO,
      BudiGeneratorRequestDTO,
      void
    >(this, url, data).fetch();
  }
}

export default Api;
