import React from "react";

type ContextValue = {
  footer: HTMLDivElement | null;
  updateFooter: (element: HTMLDivElement | null) => void;
};

const DOMElementContext = React.createContext<ContextValue | undefined>(
  undefined
);

type DOMElementProviderProps = { children: React.ReactNode };

function DOMElementProvider({ children }: DOMElementProviderProps) {
  const [footer, setFooter] = React.useState<HTMLDivElement | null>(null);

  const contextState = React.useMemo(
    (): ContextValue => ({
      footer,
      updateFooter: (element) => setFooter(element),
    }),
    [footer]
  );

  return (
    <DOMElementContext.Provider value={contextState}>
      {children}
    </DOMElementContext.Provider>
  );
}

function useDOMElement() {
  const context = React.useContext(DOMElementContext);

  if (context === undefined) {
    throw new Error("useDOMElement must be used within a DOMElementProvider");
  }

  return context;
}

export { DOMElementProvider, useDOMElement };
