import { DirectionalHint, IButtonProps, TeachingBubble } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { LocalStorageKeys } from "../constants";
import { useTeachingBubblePrioritization } from "../teachingBubblePrioitizationContext";
import {
  localStorageSetAddValue,
  localStorageSetContains,
} from "../utils/localStorageStringSet";
import { TeachingBubblePortal } from "./TeachingBubblePortal";

export const teachingPriority = [
  "MyOrderMenuItem",
  "MyOrderSubmit",
  "MyOrderEmpty",
  "DashBoardArticleSearchForm",
  "AllocationEditMode",
  "ArticleListEditArticle",
  "ArticleListViewArticle",
  "ArticleEditSubmit",
  "ArticleEditMode",
] as const;

export type TeachingId = typeof teachingPriority[number];

type TeachingBubbleTriggerProps = {
  children?: React.ReactNode;
  teachingId: TeachingId;
  target?: string;
  directionalHint?: DirectionalHint;
};

/** Wir benutzen den localStorage des Browsers, um die Bestätigung einer Teaching Bubble über Tabs hinweg zu koordinieren. */
const localStorageKey = LocalStorageKeys.TeachingConfirmed;

function isConfirmed(teachingId: string) {
  return localStorageSetContains(localStorageKey, teachingId);
}

function markConfirmed(teachingId: string) {
  localStorageSetAddValue(localStorageKey, teachingId);
}

export function TeachingBubbleTrigger(props: TeachingBubbleTriggerProps) {
  const {
    children,
    teachingId,
    target = `[data-teaching-id='${teachingId}']`,
    directionalHint = DirectionalHint.bottomAutoEdge,
  } = props;
  const { t } = useTranslation();
  const { requestDisplayState, shouldDisplay } =
    useTeachingBubblePrioritization(teachingId);

  React.useEffect(() => {
    const initiallyConfirmed = isConfirmed(teachingId);
    requestDisplayState(!initiallyConfirmed);

    let didCancel = false;
    const listener = (e: StorageEvent) => {
      if (e.key === localStorageKey && !didCancel) {
        requestDisplayState(!isConfirmed(teachingId));
      }
    };

    window.addEventListener("storage", listener);
    return () => {
      didCancel = true;
      requestDisplayState(false);
      window.removeEventListener("storage", listener);
    };
  }, [teachingId, requestDisplayState]);

  const teachingText = t(`teaching.${teachingId}`);

  const transientDismiss = () => {
    requestDisplayState(false);
  };

  const persistedDismiss = () => {
    requestDisplayState(false);
    markConfirmed(teachingId);
  };

  const primaryButtonProps: IButtonProps = {
    children: t("teaching.understood"),
    onClick: persistedDismiss,
  };

  return (
    <>
      {children}
      {shouldDisplay && (
        <TeachingBubblePortal>
          <TeachingBubble
            calloutProps={{
              directionalHint,
            }}
            focusTrapZoneProps={{ disabled: true }}
            forceFocusInsideTrap
            target={target}
            primaryButtonProps={primaryButtonProps}
            onDismiss={transientDismiss}
          >
            {teachingText}
          </TeachingBubble>
        </TeachingBubblePortal>
      )}
    </>
  );
}
