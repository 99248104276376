/* Hilfsfunktionen für eine Menge von Strings, die im localstorage des Browser gespeichert werden. */

const EMPTY_LIST: string[] = [];

/**
 * Liefert alle unter einem localStorageKey gespeicherten strings. Falls der gespeicherte Wert
 * nicht existiert oder nicht als array von strings geparst werden kann wird ein leeres array
 * zurückgeliefert.
 */
function localStorageSetReadAllValues(localStorageKey: string) {
  const json = localStorage.getItem(localStorageKey);
  const defaultValue = EMPTY_LIST;
  if (!json) {
    return defaultValue;
  }
  try {
    const value = JSON.parse(json);
    if (Array.isArray(value) && value.every((v) => typeof v === "string")) {
      return value as string[];
    } else {
      console.log("Using fallback for unexpected local storage value", {
        localStorageKey,
        json,
        defaultValue,
      });
      return defaultValue;
    }
  } catch (e) {
    console.log(
      "Using fallback for local storage value that triggered exception",
      {
        e,
        localStorageKey,
        json,
        defaultValue,
      }
    );
    return defaultValue;
  }
}

function saveToLocalStorage(localStorageKey: string, value: string[]) {
  localStorage.setItem(localStorageKey, JSON.stringify(value));
}

/** Entscheidet, ob ein Wert in der Menge der Werte unter dem localStorageKey enthalten ist. */
function localStorageSetContains(localStorageKey: string, value: string) {
  return localStorageSetReadAllValues(localStorageKey).includes(value);
}

/** Fügt zur Menge der Werte unter dem localStorageKey einen neuen Wert hinzu, wenn er noch nicht enthalten ist. */
function localStorageSetAddValue(localStorageKey: string, value: string) {
  const current = localStorageSetReadAllValues(localStorageKey);
  if (!current.includes(value)) {
    saveToLocalStorage(localStorageKey, [...current, value]);
  }
}

export {
  localStorageSetContains,
  localStorageSetAddValue,
  localStorageSetReadAllValues,
};
