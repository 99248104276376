import React, { useCallback, useMemo } from "react";
import { v4 } from "uuid";
import { Message, MessageContent } from "./messageContext";

type MessageActionsProviderProps = {
  children: React.ReactNode;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
};

type State = {
  showMessage: (message: MessageContent) => void;
  hideMessage: (message: Message) => void;
};

const MessageActionsContext = React.createContext<State | undefined>(undefined);

function MessageActionsProvider({
  children,
  setMessages,
}: MessageActionsProviderProps) {
  const removeMessage = useCallback(
    (message: Message) => {
      setMessages((state) => state.filter((m) => m.id !== message.id));
    },
    [setMessages]
  );

  const autoRemoveMessage = useCallback(
    (message: Message, duration: number) => {
      setTimeout(() => {
        removeMessage(message);
      }, duration);
    },
    [removeMessage]
  );

  const showMessage = useCallback(
    (content: MessageContent) => {
      const message: Message = { ...content, id: v4() };

      setMessages((state) => {
        if (state.find((item) => item.text === message.text)) {
          return state;
        }
        return [message, ...state];
      });

      switch (message.type) {
        case "error":
          autoRemoveMessage(message, 10000);
          break;
        case "success":
          autoRemoveMessage(message, 3000);
          break;
        case "warning":
          autoRemoveMessage(message, 5000);
          break;
      }
    },
    [autoRemoveMessage, setMessages]
  );

  const hideMessage = useCallback(
    (message: Message) => {
      setMessages((state) =>
        state.map((m) =>
          m.id === message.id ? { ...message, hidden: true } : m
        )
      );
    },
    [setMessages]
  );

  const value = useMemo(
    () => ({
      showMessage,
      hideMessage,
    }),
    [hideMessage, showMessage]
  );

  return (
    <MessageActionsContext.Provider value={value}>
      {children}
    </MessageActionsContext.Provider>
  );
}

function useMessageActions() {
  const context = React.useContext(MessageActionsContext);

  if (context === undefined) {
    throw new Error(
      "useMessageActions must be used within a MessageActionsProvider"
    );
  }

  return context;
}

export { MessageActionsProvider, useMessageActions };
