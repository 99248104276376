import {
  Icon,
  Link,
  mergeStyleSets,
  Text,
  Theme,
  useTheme,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDOMElement } from "../contexts/domElementContext";
import { useIntersecting } from "../hooks/useIntersecting";
import Container from "./Container";
import TermsOfUseModal from "./TermsOfUseModal";

function getClassNames(
  theme: Theme,
  fixed: boolean,
  isFixedFormFooterVisible: boolean,
  isScrolled: boolean
) {
  return mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderTop: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    nav: {
      display: "flex",
      justifyContent: "flex-end",
      margin: 0,
      marginBottom: "1rem",
      li: {
        listStyleType: "none",
        marginLeft: "0.75rem",
      },
      "@media (min-width: 768px)": {
        order: 1,
        marginBottom: 0,
      },
    },
    copyright: {
      display: "flex",
      justifyContent: "flex-start",
      fontWeight: 600,
      marginBottom: "0.625rem",
      "@media (min-width: 768px)": {
        order: 0,
        justifyContent: "flex-end",
      },
    },
    formFooterRoot: {
      height: FORM_FOOTER_HEIGHT,
      position: "relative",
      width: "100%",
      "& > .form-footer": {
        height: FORM_FOOTER_HEIGHT,
        width: "100%",
        position: fixed ? "fixed" : undefined,
        bottom: fixed ? 0 : undefined,
        left: fixed ? 0 : undefined,
        right: fixed ? 0 : undefined,
      },
      "& > .fab": {
        height: FORM_FOOTER_HEIGHT,
        position: fixed ? "fixed" : "absolute",
        bottom: fixed ? 0 : undefined,
        right: 0,
        zIndex: 99,
      },
    },
    fab: {
      display: "flex",
      transition: "opacity .3s",
      opacity: isScrolled ? 1 : 0,
      pointerEvents: isScrolled ? "auto" : "none",
      width: 50,
      height: 50,
      borderRadius: 25,
      border: "1px solid ",
      borderColor: theme.palette.themePrimary,
      backgroundColor: theme.palette.white,
      alignItems: "center",
      justifyContent: "center",
      marginRight: "2rem",
      cursor: "pointer",
      position: "absolute",
      bottom: isFixedFormFooterVisible
        ? `calc(2rem + ${FORM_FOOTER_HEIGHT})`
        : "2rem",
      right: 0,
    },
    fixedFabContainer: {
      position: "fixed",
      bottom: 0,
      right: 0,
    },
    fabContainer: {
      height: 0,
      position: "relative",
    },
  });
}

export const FORM_FOOTER_HEIGHT = "6rem";

export default function Footer() {
  const theme = useTheme();
  const { updateFooter } = useDOMElement();

  const isFooterIntersecting = useIntersecting("#footer", true);
  const [isFixedFormFooterVisible, setIsFixedFormFooterVisible] =
    React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] =
    React.useState(false);

  const styles = getClassNames(
    theme,
    !isFooterIntersecting,
    isFixedFormFooterVisible,
    isScrolled
  );
  const { t } = useTranslation();

  const version = process.env.REACT_APP_VERSION_ID;
  const title =
    version &&
    t("footer.version", {
      version,
    });

  React.useEffect(() => {
    function callback() {
      setIsScrolled(window.scrollY > 50);
    }

    document.addEventListener("scroll", callback);

    return () => {
      document.removeEventListener("scroll", callback);
    };
  }, []);

  React.useEffect(() => {
    const callback: MutationCallback = (mutations) => {
      const sum = mutations.reduce(
        (carry, mutation) =>
          carry + mutation.addedNodes.length - mutation.removedNodes.length,
        0
      );

      setIsFixedFormFooterVisible((state) => (sum !== 0 ? !state : state));
    };

    const observer = new MutationObserver(callback);
    const target = document.querySelector("#form-footer-root");

    if (target) {
      observer.observe(target, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function toggleTermsOfUseModal() {
    setIsTermsOfUseModalVisible((state) => !state);
  }

  const fab = (
    <div
      className={styles.fab}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <Icon
        iconName="chevronUp"
        style={{ color: theme.palette.themePrimary }}
      />
    </div>
  );

  return (
    <>
      <div id="teaching-bubble-root" />
      <div
        id="form-footer-root"
        ref={(ref) => updateFooter(ref)}
        className={styles.formFooterRoot}
      />
      <div
        className={styles.fixedFabContainer}
        style={{
          display: !isFooterIntersecting ? "block" : "none",
        }}
      >
        {fab}
      </div>
      <div
        id="up-scroll-fab"
        className={styles.fabContainer}
        style={{
          visibility: !isFooterIntersecting ? "hidden" : undefined,
        }}
      >
        {fab}
      </div>
      <Container testId={"@footer"}>
        <footer id="footer" className={styles.container}>
          <ul className={styles.nav}>
            <li>
              <Link onClick={toggleTermsOfUseModal}>
                {t("footer.termsOfUse")}
              </Link>
            </li>
            <li>
              <Link
                href="http://www.ifaffm.de/de/impressum.html"
                target="_blank"
              >
                {t("footer.imprint")}
              </Link>
            </li>
            <li>
              <Link
                href="http://www.ifaffm.de/de/datenschutz.html"
                target="_blank"
              >
                {t("footer.privacy")}
              </Link>
            </li>
          </ul>
          <Text block className={styles.copyright} title={title}>
            &#169; Informationsstelle für Arzneispezialitäten – IFA GmbH
          </Text>
        </footer>
      </Container>
      <TermsOfUseModal
        isVisible={isTermsOfUseModalVisible}
        onClose={toggleTermsOfUseModal}
      />
    </>
  );
}
