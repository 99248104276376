import {
  Checkbox,
  Link,
  mergeStyleSets,
  Text,
  Theme,
  useTheme,
} from "@fluentui/react";
import { ReactNode, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useApi } from "../apiContext";
import { useAuthDispatch, useAuthState } from "../authContext";
import { addApiErrors } from "../utils/formUtils";
import CardForm from "./CardForm";
import Layout from "./Layout";
import TermsOfUseModal from "./TermsOfUseModal";

type TermsOfUseGuardProps = {
  children: ReactNode;
};

export type TermsOfUseGuardFormFields = {
  nutzungsbedingungenAkzeptiert: boolean;
};

function getClassNames(theme: Theme) {
  return mergeStyleSets({
    errorMessage: {
      color: theme.palette.red,
    },
    acceptTermsOfUseContainer: { marginTop: "5px 0", paddingTop: "1.5rem" },
  });
}

export default function TermsOfUseGuard({ children }: TermsOfUseGuardProps) {
  const auth = useAuthState();
  const authDispatch = useAuthDispatch();
  const { t } = useTranslation();
  const api = useApi();
  const {
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<TermsOfUseGuardFormFields>();
  const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] =
    useState(false);
  const theme = useTheme();
  const styles = getClassNames(theme);

  const onSubmit = handleSubmit(async (data) => {
    const response = await api?.postTermsOfUse(data);
    if (response?.type === "Success") {
      authDispatch({ type: "FETCH_SUCCESS", payload: response.data });
    } else if (response?.type === "ValidationFailure") {
      addApiErrors(response.errors, setError);
    }
  });

  function handleDismissTermsOfUseModal() {
    setIsTermsOfUseModalVisible(false);
  }

  function handleOpenTermsofUseModal(
    event: React.MouseEvent<
      HTMLElement | HTMLAnchorElement | HTMLButtonElement,
      MouseEvent
    >
  ) {
    event.preventDefault();
    setIsTermsOfUseModalVisible(true);
  }

  if (
    auth.isLoading ||
    !auth.profile ||
    auth.profile?.nutzungsbedingungenAkzeptiert
  ) {
    return <>{children}</>;
  }

  return (
    <Layout center>
      <CardForm
        heading={t("termsOfUseGuard.termsOfUse")}
        subHeading={t("termsOfUseGuard.acceptTermsOfUse")}
        onSubmit={onSubmit}
        submitLabel={t("action.saveChanges")}
        disabled={false}
        loading={isSubmitting}
      >
        <Controller
          name="nutzungsbedingungenAkzeptiert"
          control={control}
          defaultValue={false}
          render={({ field: { ref, value, onChange, ...field } }) => (
            <div className={styles.acceptTermsOfUseContainer}>
              <Checkbox
                {...field}
                checked={value}
                onChange={(
                  ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                  checked?: boolean
                ) => {
                  onChange(checked);
                }}
                onRenderLabel={() => (
                  <span>
                    <Trans i18nKey="resetPassword.acceptTermsOfUse">
                      <Link as="a" onClick={handleOpenTermsofUseModal}></Link>
                    </Trans>
                  </span>
                )}
              />
              {errors.nutzungsbedingungenAkzeptiert && (
                <Text className={styles.errorMessage} variant="small">
                  {errors.nutzungsbedingungenAkzeptiert.message}
                </Text>
              )}
            </div>
          )}
        />
      </CardForm>
      <TermsOfUseModal
        isVisible={isTermsOfUseModalVisible}
        onClose={handleDismissTermsOfUseModal}
      />
    </Layout>
  );
}
