import {
  keyframes,
  memoizeFunction,
  mergeStyleSets,
  Text,
  Theme,
  useTheme,
} from "@fluentui/react";

export enum CircularProgressIndicatorMode {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

export type CircularProgressIndicatorProps = {
  size: number;
  progress: number;
  isLabelVisible?: boolean;
  mode?: CircularProgressIndicatorMode;
};

function deriveEndAngle(progress: number): number {
  return 360 * (0.9 * progress + 0.1);
}

const spinAnimation = memoizeFunction(function () {
  return keyframes({
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  });
});

function getClassNames(theme: Theme, dimension: number, strokeWidth: number) {
  return mergeStyleSets({
    absolute: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    background: {
      width: dimension - strokeWidth,
      height: dimension - strokeWidth,
      borderRadius: "50%",
      borderWidth: strokeWidth,
      borderColor: theme.palette.neutralLight,
      borderStyle: "solid",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      position: "relative",
      height: dimension,
      width: dimension,
    },
    rotate: {
      animationName: spinAnimation(),
      animationDuration: "1.3s",
      animationIterationCount: "infinite",
      animationTimingFunction: "cubic-bezier(.53,.21,.29,.67)",
      height: "100%",
      width: "100%",
    },
  });
}

function deriveColor(
  theme: Theme,
  mode?: CircularProgressIndicatorMode
): string {
  switch (mode) {
    case CircularProgressIndicatorMode.ERROR:
      return theme.palette.red;
    case CircularProgressIndicatorMode.SUCCESS:
      return theme.palette.green;
    case CircularProgressIndicatorMode.WARNING:
      return theme.palette.yellow;
    default:
      return theme.palette.themePrimary;
  }
}

export default function CircularProgressIndicator({
  size,
  progress,
  isLabelVisible = true,
  mode,
}: CircularProgressIndicatorProps) {
  const radius = size / 2;
  const theme = useTheme();

  const strokeWidth = size / 8;
  const start = { x: radius + strokeWidth, y: radius + strokeWidth };
  const dimension = 2 * (radius + strokeWidth);
  const styles = getClassNames(theme, dimension, strokeWidth);
  const label = `${Math.round(progress * 100)}%`;

  const indicatorColor = deriveColor(theme, mode);

  return (
    <div className={`${styles.container} ${styles.center}`}>
      <div className={styles.background} />
      <div className={`${styles.absolute} ${styles.rotate}`}>
        <svg
          version="1.1"
          width={dimension}
          height={dimension}
          xmlns="http://www.w3.org/2000/svg"
          style={{ transition: "transform 1s" }}
        >
          <path
            d={describeArc(
              start.x,
              start.y,
              radius,
              0,
              deriveEndAngle(progress)
            )}
            fill="none"
            stroke={indicatorColor}
            strokeWidth={strokeWidth}
          />
          {progress > 0.99 ? (
            <>
              <circle
                cx={dimension / 2}
                cy={dimension / 2}
                r={dimension / 2}
                fill={deriveColor(theme, mode)}
              />
              <circle
                cx={dimension / 2}
                cy={dimension / 2}
                r={radius}
                fill={theme.palette.white}
              />
            </>
          ) : null}
        </svg>
      </div>
      {isLabelVisible && (
        <div className={`${styles.absolute} ${styles.center}`}>
          <Text
            styles={{
              root: {
                fontSize: dimension / 5,
                fontWeight: "bold",
                color: indicatorColor,
              },
            }}
          >
            {label}
          </Text>
        </div>
      )}
    </div>
  );
}

/* Helper functions */
function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}
