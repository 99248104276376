import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const action = useNavigationType();

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname, action]);

  return null;
}
