// Sollte mit dem `enum Feature` in folgende Datei entsprechen
// ./meldeverfahren-backend/meldeverfahren/src/main/java/de/ifaffm/portal/meldeverfahren/controller/FeatureFlags.java
export enum Feature {
  AUFTRAG_ZURUECKSTELLEN = "AUFTRAG_ZURUECKSTELLEN",
}

export function isActive(feature: Feature) {
  return parseActiveFeatureFlags().includes(feature);
}

function hasProp<K extends PropertyKey>(
  data: object,
  prop: K
): data is Record<K, unknown> {
  return prop in data;
}

function parseFeatureList(featureFlags: string | undefined): Feature[] {
  if (featureFlags === undefined) {
    return [];
  }

  return featureFlags
    .split(",")
    .filter((flag): flag is Feature => flag in Feature);
}

function parseActiveFeatureFlags() {
  const windowForFeatureFlags = window as unknown;
  let featureFlags: string | undefined = undefined;

  if (process.env.NODE_ENV === "development") {
    featureFlags = process.env.REACT_APP_FEATURE_FLAGS;
  } else if (
    typeof windowForFeatureFlags === "object" &&
    windowForFeatureFlags !== null &&
    hasProp(windowForFeatureFlags, "IfaPortalConfig") &&
    typeof windowForFeatureFlags.IfaPortalConfig === "object" &&
    windowForFeatureFlags.IfaPortalConfig !== null &&
    hasProp(windowForFeatureFlags.IfaPortalConfig, "featureFlags") &&
    typeof windowForFeatureFlags.IfaPortalConfig.featureFlags === "string"
  ) {
    featureFlags = windowForFeatureFlags.IfaPortalConfig.featureFlags;
  }

  return parseFeatureList(featureFlags);
}
