import React from "react";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalFrame from "./ModalFrame";
import ModalHeader from "./ModalHeader";

type ModalProps = {
  isVisible: boolean;
  destructive?: boolean;
  title: string;
  children: ((isLoading: boolean) => React.ReactNode) | React.ReactNode;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
  isSubmitDisabled?: boolean;
  formId?: string;
  dataTestId?: string;
};

export default function Modal({
  isVisible,
  destructive,
  title,
  children,
  isSubmitDisabled = false,
  submitLabel,
  formId,
  cancelLabel,
  onCancel,
  onSubmit,
  dataTestId,
}: ModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (!onSubmit || isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit(event);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancel() {
    if (!onCancel || isLoading) {
      return;
    }

    onCancel();
  }

  return (
    <ModalFrame isVisible={isVisible} maxWidth="640px" dataTestId={dataTestId}>
      <form
        onSubmit={handleSubmit}
        style={{ alignSelf: "stretch" }}
        id={formId}
      >
        <ModalHeader title={title} />
        <ModalBody isLoading={isLoading}>{children}</ModalBody>
        <ModalFooter
          onPrimaryAction={"submit"}
          onCancel={onCancel && handleCancel}
          isLoading={isLoading}
          isPrimaryActionDisabled={isSubmitDisabled}
          primaryActionLabel={submitLabel}
          cancelLabel={cancelLabel}
          destructive={destructive}
          formId={formId}
        />
      </form>
    </ModalFrame>
  );
}
