import H2 from "./H2";

export default function TermsOfUse() {
  return (
    <div>
      <H2>Zugangsberechtigung</H2>
      <p>
        Mit der Registrierung als meldeberechtigte Person des Anbieters
        (Zugangsberechtigte) akzeptiert der Zugangsberechtigte die nachfolgenden
        Nutzungsbedingungen für das IFA-Portal.
      </p>
      <p>
        Die Verarbeitung der personenbezogenen Daten der Zugangsberechtigung ist
        in den Datenschutzbestimmungen des IFA-Portals geregelt, die von jeder
        Seite des Portals über den Punkt "Datenschutz" erreichbar sind.
      </p>
      <p>
        Der Zugangsberechtigte sichert bei der persönlichen Registrierung zu,
        dass er Mitarbeiter des Anbieters ist oder unter Vollmacht eines
        Anbieters beauftragt ist IFA-Meldungen abzugeben und alle von ihm bei
        der Registrierung angegebenen Daten zutreffend und vollständig sind.
        Ferner versichert der Zugangsberechtigte, dass ihm die Erlaubnis gegeben
        wurde, das IFA-Portal im Rahmen seiner beruflichen Tätigkeit für
        IFA-Meldungen zu nutzen. Zugangsberechtigte dürfen keine Pseudonyme oder
        Künstlernamen verwenden und sich nur einmal registrieren und nur ein
        Nutzerprofil anlegen.
      </p>
      <p>
        Der Zugangsberechtigte erklärt sich ausdrücklich damit einverstanden,
        dass die von ihm gemeldeten Inhalte auch nach Beendigung der
        Nutzungsberechtigung des IFA-Portals in der IFA-Datenbank verfügbar
        sind.
      </p>
      <p>
        Unter der Voraussetzung der Einhaltung dieser Nutzungsbedingungen
        gewährt IFA den Zugangsberechtigten ein beschränktes, einfaches, nicht
        übertragbares und nicht unterlizenzierbares Nutzungsrecht, um für den
        Anbieter auf das IFA-Portal zuzugreifen und seine Artikeldaten zu
        melden.
      </p>

      <H2>Zugangskennung</H2>
      <p>
        Die Zugangsberechtigten benötigen Zugangsdaten, um das IFA-Portal nutzen
        zu können.
      </p>
      <p>
        Wenn Zugangsberechtigte das IFA-Portal nutzen, sind sie für die
        Sicherstellung der Vertraulichkeit ihrer Zugangsdaten und für die
        Beschränkung des Zugangs zu ihrem Computer und ihren mobilen Geräten
        verantwortlich. Die Zugangsberechtigten sind für alle Aktivitäten
        verantwortlich, die über ihre Zugangskennung vorgenommen werden. Sie
        sollten alle erforderlichen Schritte unternehmen, um sicherzustellen,
        dass ihr Passwort geheim gehalten und sicher aufbewahrt wird. Die
        Zugangsberechtigten oder der Anbieter soll IFA unverzüglich informieren,
        wenn Anlass zur Sorge besteht, dass ein Dritter Kenntnis vom Passwort
        der meldeberechtigten Person erlangt hat oder das Passwort unautorisiert
        genutzt wird oder dies wahrscheinlich ist. Anbieter und dessen
        Zugangsberechtigte sind dafür verantwortlich, sicherzustellen, dass die
        Zugangsdaten stets korrekt und vollständig sind und Änderungen in der
        Person des Zugangsberechtigten sofort aktualisiert werden. Die
        Informationen der Zugangsberechtigten können im Bereich
        Benutzerverwaltung des IFA-Portals eingesehen werden.
      </p>
      <p>
        Zugangsberechtigte dürfen das IFA-Portal nicht in einer Weise verwenden,
        die dazu geeignet ist, das IFA-Portal oder den Zugang dazu zu
        unterbrechen, zu beschädigen oder in sonstiger Art zu beeinträchtigen.
        IFA behält sich das Recht vor, Zugangsberechtigten die Zugangskennungen
        zu sperren, wenn die Nutzung der Zugangskennung berechtigten Grund dazu
        gibt. Verletzten Zugangsberechtigte diese Nutzungsbedingungen und IFA
        unternimmt hiergegen zunächst nichts, ist IFA weiterhin berechtigt, von
        ihren Rechten bei jedem anderen Vorfall, in dem Nutzungsbedingungen
        verletzt werden, Gebrauch zu machen.
      </p>
      <p>
        Zugangsberechtigte können die Zugangsberechtigung zum IFA-Portal
        jederzeit kündigen.
      </p>
      <H2>IFA Portal</H2>
      <p>
        IFA bemüht sich stets sicherzustellen, dass das IFA Portal ohne
        Unterbrechungen verfügbar ist und Übermittlungen fehlerfrei sind. Durch
        die Beschaffenheit des Internets kann dies jedoch nicht garantiert
        werden. Der Zugriff auf das IFA Portal kann gelegentlich unterbrochen
        oder beschränkt sein, um Instandsetzungen, Wartungen oder Änderungen
        einzurichten.
      </p>
      <p>
        IFA haftet unbeschränkt, soweit die Schadensursache auf einer
        vorsätzlichen oder grob fahrlässigen Pflichtverletzung von IFA oder
        eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruht. IFA haftet
        ferner für die leicht fahrlässige Verletzung von wesentlichen Pflichten.
        Wesentlich sind Pflichten, deren Verletzung die Nutzung des IFA Portals
        gefährdet oder deren Erfüllung die ordnungsgemäße Nutzung überhaupt erst
        ermöglicht und auf deren Einhaltung die Zugangsberechtigten regelmäßig
        vertrauen. In diesem Fall haftet IFA nur für den vorhersehbaren,
        vertragstypischen Schaden. IFA haftet nicht für die leicht fahrlässige
        Verletzung anderer als der in den vorstehenden Sätzen genannten
        Pflichten. Die vorstehenden Haftungsbeschränkungen gelten nicht bei
        Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach
        Übernahme einer Garantie für die Beschaffenheit des Portals und bei
        arglistig verschwiegenen Mängeln. Soweit die Haftung von IFA
        ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche
        Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.
      </p>
      <p>
        Gegenüber Nutzungsberechtigten des IFA Portals ist IFA berechtigt, die
        Nutzungsbedingung des IFA-Portals ganz oder teilweise, jederzeit aus den
        folgenden Gründen zu ändern: Aus rechtlichen oder regulatorischen
        Gründen; aus Sicherheitsgründen; um Dienste weiterzuentwickeln oder zu
        optimieren sowie um zusätzliche Dienste hinzuzufügen; um dem technischen
        Fortschritt Rechnung zu tragen und technische Anpassungen vorzunehmen
        und um die künftige Funktionsfähigkeit sicherzustellen. Nimmt IFA
        Änderungen vor, setzt sie die Zugangsberechtigten hierüber mit
        angemessener Frist in Kenntnis und weist sie auf die ihnen zustehende
        Rechte hin. Zugangsberechtigte haben jederzeit die Möglichkeit, ihre
        Zugangsberechtigung zu kündigen.
      </p>
      <p>
        Auf diese Zugangsbedingungen findet das deutsche Recht Anwendung.
        Ausschließlicher Gerichtsstand für alle Ansprüche auf Grund dieser
        Zugangsberechtigung ist Frankfurt am Main als Sitz der IFA GmbH.
      </p>
    </div>
  );
}
