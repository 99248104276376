import React from "react";
import { MessageActionsProvider } from "./messageActionsContext";

export type MessageType = "error" | "warning" | "success";

export type Message = MessageContent & {
  id: string;
  hidden?: boolean;
};

export type MessageContent = {
  text: string;
  type: MessageType;
};

type MessageProviderProps = { children: React.ReactNode };

type State = {
  messages: Message[];
};

const MessageContext = React.createContext<State | undefined>(undefined);

function MessageProvider({ children }: MessageProviderProps) {
  const [messages, setMessages] = React.useState<Message[]>([]);

  return (
    <MessageContext.Provider value={{ messages }}>
      <MessageActionsProvider setMessages={setMessages}>
        {children}
      </MessageActionsProvider>
    </MessageContext.Provider>
  );
}

function useMessage() {
  const context = React.useContext(MessageContext);

  if (context === undefined) {
    throw new Error("useMessage must be used within a MessageProvider");
  }

  return context;
}

export { MessageProvider, useMessage };
