import { mergeStyleSets } from "@fluentui/react";
import React from "react";

import ActionBar from "./ActionBar";
import Footer from "./Footer";
import Header from "./Header";
import Messages from "./Messages";

type LayoutProps = {
  children: React.ReactNode;
  center?: boolean;
  actions?: React.ReactNode;
  isNavigationBlocked?: boolean;
};

function getClassNames() {
  return mergeStyleSets({
    container: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    main: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    centerWrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
    },
  });
}

export default function Layout({
  children,
  center,
  actions,
  isNavigationBlocked,
}: LayoutProps) {
  const styles = getClassNames();

  return (
    <div className={styles.container}>
      <Messages />
      <Header isNavigationBlocked={isNavigationBlocked} />
      <ActionBar align="start">{actions}</ActionBar>
      <main className={styles.main}>
        {center ? (
          <div className={styles.centerWrapper}>{children}</div>
        ) : (
          <>{children}</>
        )}
      </main>
      <Footer />
    </div>
  );
}
