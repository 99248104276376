import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import Container from "./Container";

type ActionBarProps = {
  children: React.ReactNode;
  className?: string;
  align: "start" | "end";
};

function getClassNames(children: React.ReactNode, align: "start" | "end") {
  const count = React.Children.toArray(children).filter((c) => c).length;
  const flexAlign = "flex-" + align;
  return mergeStyleSets({
    container: {
      display: "flex",
      justifyContent: count > 1 ? "space-between" : flexAlign,
      marginBottom: "2rem",
      "& > .action-group": {
        display: "flex",
      },
      "& > .action-group > *": {
        marginLeft: "2rem",
      },
    },
  });
}

export default function ActionBar({
  children,
  className,
  align,
}: ActionBarProps) {
  const styles = getClassNames(children, align);

  return (
    <Container testId="@action-bar">
      <div className={`${styles.container} ${className || ""}`}>{children}</div>
    </Container>
  );
}
