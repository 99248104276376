import React from "react";
import ReactDOM from "react-dom";

/**
 * Verschiebt Teaching Bubbles in ein eigenes div, das nah an der Wurzel des Komponentenbaums liegt.
 * Dies vermeidet, dass die TeachingBubbles im DOM des normalen Komponentenbaums liegen und dort
 * Einfluss auf das Styling mit CSS haben. Der Name dieser Komponente hat nichts damit zu tun, dass wir
 * eine "Portal-Webseite" implemntieren, sondern stammt von der React Funktionalität, einen "Durchgang
 * in eine andere Welt" zu schaffen (https://reactjs.org/docs/portals.html).
 */
export function TeachingBubblePortal({
  children,
}: {
  children: React.ReactNode;
}) {
  const element = React.useRef(document.createElement("div"));

  React.useEffect(() => {
    const el = element.current;
    const formFooterRoot = document.getElementById("teaching-bubble-root");
    if (formFooterRoot === null) {
      return;
    }
    formFooterRoot.appendChild(el);

    return () => {
      formFooterRoot.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, element.current);
}
