import React from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { ArticleFormFields } from "./modules/article/components/ArticleForm";

type ArticleFormProviderProps = { children: React.ReactNode };

export type ArticleFormContextState = UseFormReturn<ArticleFormFields>;

export type ArticleFormDirtyFields = ReturnType<
  typeof useArticleForm
>["formState"]["dirtyFields"];

export type ArticleFormDefaultValues = ReturnType<
  typeof useArticleForm
>["formState"]["defaultValues"];

function ArticleFormProvider({ children }: ArticleFormProviderProps) {
  const methods = useForm<ArticleFormFields>();

  return <FormProvider {...methods}>{children}</FormProvider>;
}

function useArticleForm() {
  return useFormContext<ArticleFormFields>();
}

function useIsArticleFormDirty() {
  const methods = useFormContext<ArticleFormFields>();
  const {
    formState: { dirtyFields },
  } = methods;

  return Object.keys(dirtyFields).length > 0;
}

export { ArticleFormProvider, useArticleForm, useIsArticleFormDirty };
