import { mergeStyleSets, Theme, useTheme } from "@fluentui/react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

type NavLinkProps = {
  children: string;
  to: string;
  exact?: boolean;
  onClick?: () => void;
};

function getClassNames(theme: Theme) {
  return mergeStyleSets({
    container: {
      color: theme.palette.themePrimary,
      textDecoration: "none",
      ":hover, :active": {
        textDecoration: "underline",
        color: theme.palette.blueDark,
      },
    },
    active: {
      fontWeight: 600,
    },
  });
}

export default function NavLink({
  children,
  to,
  exact,
  onClick,
}: NavLinkProps) {
  const theme = useTheme();
  const styles = getClassNames(theme);

  return (
    <ReactRouterNavLink
      className={({ isActive }) =>
        isActive ? `${styles.container} ${styles.active}` : styles.container
      }
      to={to}
      end={exact}
      onClick={onClick}
    >
      {children}
    </ReactRouterNavLink>
  );
}
