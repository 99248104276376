import { Text } from "@fluentui/react";
import React from "react";

type H2Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export default function H2({ children, style = {} }: H2Props) {
  return (
    <Text as="h2" variant="large" block style={{ margin: 0, ...style }}>
      {children}
    </Text>
  );
}
