import { IPalette, IStyle } from "@fluentui/react";
import { ErrorOption } from "react-hook-form";
import { AppPalette } from "theme";
import { ValidationFailureMessages } from "../api/ApiResponse";

export function getValidationBackgroundStyle(
  palette: IPalette,
  isDirty: boolean,
  hasErrors: boolean,
  hasWarnings: boolean
): IStyle {
  return [
    //#region Styling im Fall eines Fehlers
    hasErrors &&
      isDirty && {
        backgroundColor: (palette as AppPalette).redLight,
      },
    //#endregion

    //#region Styling im Fall einer Warnung
    hasWarnings &&
      !hasErrors &&
      isDirty && {
        backgroundColor: palette.yellowLight,
      },
    //#endregion

    //#region Styling im Fall einer Änderung
    !hasWarnings &&
      !hasErrors &&
      isDirty && {
        backgroundColor: palette.themeLighterAlt,
      },
    //#endregion
  ];
}

export function getValidationBorderStyles(
  palette: IPalette,
  isDirty: boolean,
  hasErrors: boolean,
  hasWarnings: boolean,
  hoverOverride?: string
): IStyle {
  return [
    //#region Styling im Fall eines Fehlers
    hasErrors && {
      borderColor: palette.red,
      [`&:hover ${hoverOverride ? hoverOverride : ""}`]: {
        borderColor: palette.redDark,
      },
      "&:after": {
        borderColor: `${palette.red} !important`,
      },
    },
    //#endregion

    //#region Styling im Fall einer Warnung
    hasWarnings &&
      !hasErrors && {
        borderColor: palette.yellow,
        [`&:hover ${hoverOverride ? hoverOverride : ""}`]: {
          borderColor: palette.yellowDark,
        },
        "&:after": {
          borderColor: `${palette.yellow} !important`,
        },
      },
    //#endregion

    //#region Styling im Fall einer Änderung
    !hasWarnings &&
      !hasErrors &&
      isDirty && {
        borderColor: palette.themePrimary,
        [`&:hover ${hoverOverride ? hoverOverride : ""}`]: {
          borderColor: palette.themeDarker,
        },
      },
    //#endregion
  ];
}

export function getFieldValidationStyles(
  palette: IPalette,
  isDirty: boolean,
  hasErrors: boolean,
  hasWarnings: boolean,
  hoverOverride?: string
): IStyle {
  return [
    getValidationBackgroundStyle(palette, isDirty, hasErrors, hasWarnings),
    getValidationBorderStyles(
      palette,
      isDirty,
      hasErrors,
      hasWarnings,
      hoverOverride
    ),
  ];
}

export function addApiErrors<T>(
  errors: ValidationFailureMessages<T> | undefined,
  setError: (name: keyof T, error: ErrorOption) => void
): void {
  if (!errors) {
    return;
  }

  const keys = Object.keys(errors) as Array<keyof ValidationFailureMessages<T>>;
  keys.forEach((key) => {
    if (!errors[key as keyof T]?.length) {
      return;
    }

    setError(key, {
      type: "api",
      message: errors[key as keyof T]?.join(" - "),
    });
  });
}
