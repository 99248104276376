import {
  DefaultButton,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import DestructiveButton from "./DestructiveButton";

type ModalFooterProps = {
  destructive?: boolean;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  cancelLabel?: string;
  onPrimaryAction?: "submit" | (() => void);
  onSecondaryAction?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  isPrimaryActionDisabled?: boolean;
  formId?: string;
};

function getClassNames() {
  return mergeStyleSets({
    footer: {
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
  });
}

export default function ModalFooter({
  onPrimaryAction,
  onSecondaryAction,
  isLoading,
  isPrimaryActionDisabled,
  primaryActionLabel,
  secondaryActionLabel,
  destructive,
  onCancel,
  cancelLabel,
  formId,
}: ModalFooterProps) {
  const { t } = useTranslation();
  const styles = getClassNames();

  const primaryActionProps = {
    type: onPrimaryAction === "submit" ? "submit" : undefined,
    onClick:
      typeof onPrimaryAction === "function" ? onPrimaryAction : undefined,
  };

  return (
    <div className={styles.footer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          columnGap: "1ex",
        }}
      >
        {onPrimaryAction ? (
          !destructive ? (
            <PrimaryButton
              {...primaryActionProps}
              form={formId}
              disabled={isLoading || isPrimaryActionDisabled}
            >
              {primaryActionLabel || t("action.ok")}
            </PrimaryButton>
          ) : (
            <DestructiveButton
              {...primaryActionProps}
              disabled={isLoading || isPrimaryActionDisabled}
              title={primaryActionLabel || t("action.ok")}
            />
          )
        ) : null}
        {onSecondaryAction && (
          <DefaultButton
            type="button"
            disabled={isLoading}
            onClick={onSecondaryAction}
          >
            {secondaryActionLabel || t("action.back")}
          </DefaultButton>
        )}
      </div>
      {isLoading && <Spinner />}
      {onCancel && (
        <DefaultButton type="button" onClick={onCancel} disabled={isLoading}>
          {cancelLabel || t("action.cancel")}
        </DefaultButton>
      )}
    </div>
  );
}
