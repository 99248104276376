import { ThemeProvider } from "@fluentui/react";
import { PropsWithChildren } from "react";
import { ApiProvider } from "../apiContext";
import { ArticleFormProvider } from "../articleFormContext";
import { ArticleMetaDataProvider } from "../articleMetaDataContext";
import { AuthProvider } from "../authContext";
import { DOMElementProvider } from "../contexts/domElementContext";
import { FeatureDiscoveryProvider } from "../featureDiscoveryContext";
import { MessageProvider } from "../messageContext";
import { OrderStateProvider } from "../orderStateContext";
import { TeachingBubblePrioritizationProvider } from "../teachingBubblePrioitizationContext";
import theme from "../theme";
import ScrollToTop from "./ScrollToTop";
import TermsOfUseGuard from "./TermsOfUseGuard";

export default function RootProvider({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <DOMElementProvider>
        <MessageProvider>
          <AuthProvider>
            <ApiProvider>
              <FeatureDiscoveryProvider>
                <TeachingBubblePrioritizationProvider>
                  <ArticleMetaDataProvider>
                    <OrderStateProvider>
                      <ArticleFormProvider>
                        <ScrollToTop />
                        <TermsOfUseGuard>{children}</TermsOfUseGuard>
                      </ArticleFormProvider>
                    </OrderStateProvider>
                  </ArticleMetaDataProvider>
                </TeachingBubblePrioritizationProvider>
              </FeatureDiscoveryProvider>
            </ApiProvider>
          </AuthProvider>
        </MessageProvider>
      </DOMElementProvider>
    </ThemeProvider>
  );
}
